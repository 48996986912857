import React from 'react'
import { Box, Image, Text, Flex } from '@chakra-ui/react'
import AthleteLogo from 'assets/images/AthleteLogo.png'
import AtheleBanner from 'assets/images/AtheleteBanner.png'
import Athlete from 'assets/images/Athlete.png'
import './style.scss'
export default function ProfessionalAthleteCard() {
  return (
    <Box
      as={Flex}
      flexWrap="wrap"
      className="athleteBox"
      padding="20px 0px 20px 00px"
      w="300px"
      mx="auto"
      height="371px"
    >
      <Image height="391px" objectFit="cover" src={Athlete} />
      <Box border="0px solid green" position="absolute" zIndex="1" w="100%">
        <Text
          style={{ fontFamily: 'DELIRIUM NCV' }}
          className="fs--20"
          ml="15px"
          mt="30px"
          color="white"
        >
          PWR
        </Text>
        <Text
          style={{ fontFamily: 'DELIRIUM NCV' }}
          className="fs--45"
          ml="10px"
          mt="0px"
          color="white"
        >
          109
        </Text>
        <Image ml="10px" mb="26px" boxSize="36px" src={AthleteLogo} />
        <Flex
          p={0}
          alignItems="end"
          justifyContent="end"
          border="0px solid red"
        >
          <Flex
            direction="column"
            align="start"
            justify="center"
            border="0px solid red"
            alignSelf="end"
            w="90%"
            mx="0px"
            height="105px"
            px="25px"
            backgroundImage={AtheleBanner}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          >
            <Text
              style={{ fontFamily: 'DELIRIUM NCV' }}
              className="fs--29 fw--700"
              color="white"
            >
              Rakuten
            </Text>
            <Text
              style={{ fontFamily: 'DELIRIUM NCV' }}
              className="fs--14 fw--400"
              color="white"
            >
              lvl 1
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
