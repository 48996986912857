import React, { useState, useRef } from 'react'
import {
  Box,
  Flex,
  Text,
  Container,
  Button,
  Image,
  Stack,
  Heading,
} from '@chakra-ui/react'
import Header from 'components/header'
import Footer from 'components/footer'
import CollectionCard from 'components/collectionCard'
import {
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import GradientDividers from 'components/GradientsDevider'
import SliderCollectionCard from 'components/sliderCard'
import Slider from 'react-slick'
import { TiStarburst } from 'react-icons/ti'
import NTR1 from 'assets/images/newTrendindCollection1.png'
import BannerLogo from 'assets/images/BannerImg.png'
import ExploreBg from 'assets/images/ExploreBg.png'

import mainBgImg from 'assets/images/MainBannerFootbal.png'
import { clubData, EthleteData } from 'utils/data/landingpageData'
// import AthleteLogo from 'assets/images/AthleteLogo.png'
// import AtheleBanner from 'assets/images/AtheleteBanner.png'
// import Athlete from 'assets/images/Athlete.png'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import SliderNextArrow from 'components/sliderNextArrow'
import SliderPrevArrow from 'components/sliderPrevArrow'
import ProfessionalAthleteCard from 'components/professionalAthleteCard'
import tokenImg from 'assets/images/ClubLogo.png'
import { useGetLoggedIn } from 'utils/apis/auth.api'
import { useGetUserLogin } from 'utils/hooks'
export default function HomePage() {
  if (useGetUserLogin()) {
    const { data: loggedInUser } = useGetLoggedIn()
    console.log('login user', loggedInUser)
  }
  return (
    <>
      <Header />
      <Box position="relative" as="main">
        <Box
          position="absolute"
          top="-2.1%"
          left="0%"
          border="0px solid green"
          w="100%"
          height="2200px"
          zIndex="-1"
          backgroundImage={`url('${mainBgImg}')`}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        ></Box>
        <Box px={{ base: '0px', md: '90px' }}>
          <Banner />
        </Box>
        <Box px={{ base: '0px', md: '90px' }}>
          <TopCollections />
        </Box>

        <Box px={{ base: '0px', md: '90px' }}>
          <TrendingCollection />
        </Box>
        <Box px={{ base: '0px', md: '100px' }} mt="1rem">
          <TopNftTending title="Top Trending NFT's" />
        </Box>

        <Box px={{ base: '0px', md: '100px' }} mt="1rem">
          <TopNftTending title="Newly Listed NFt's" />
        </Box>

        <Box px={{ base: '0px', md: '100px' }} mt="1rem">
          <TopNftTending title="Explore others" />
        </Box>

        <ExploreClubs />
        <Box px={{ base: '0px', md: '100px' }} mt="1rem">
          <ProfessionalAthlete />
        </Box>
      </Box>
      <Footer />
    </>
  )
}
function Banner() {
  return (
    <Container mb="50px" maxW="1280px" px="1rem">
      <Box
        display="flex"
        w="100%"
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Text
            fontSize={{ base: '30px', md: '60px' }}
            fontWeight={700}
            color="white"
            mb="20px"
          >
            The
            <Text
              fontSize={{ base: '30px', md: '60px' }}
              fontWeight={700}
              as="span"
              bgGradient="linear-gradient(94deg, #C03DFD 0%, #4F81FD 100%)"
              bgClip="text"
            >
              {' '}
              FAN SHPERE
            </Text>
          </Text>
          <Text
            fontSize={{ base: '30px', md: '60px' }}
            fontWeight={700}
            color="white"
            mb="20px"
          >
            Marketplace Powers
          </Text>
          <Text
            fontSize={{ base: '30px', md: '60px' }}
            fontWeight={700}
            color="white"
            mb="20px"
          >
            The Player&apos;s Ecosystem
          </Text>
          <Button h="55px" rightIcon={<ArrowForwardIcon fontSize="20px" />}>
            Explore Marketplace
          </Button>
        </Box>

        <Image src={BannerLogo} maxW="400px" />
      </Box>
    </Container>
  )
}
function TopCollections() {
  return (
    <Container maxW="1280px" mb="100px" px="1rem">
      <Flex color="white" align="center" justify="space-between">
        <Text className="fs--26 fw--600" color="white">
          Top Collections
        </Text>
        <Text className="fs--16 fw--600" cursor="pointer">
          See all <ArrowForwardIcon ml="10px" />
        </Text>
      </Flex>
      <GradientDividers />
      <CollectionCard />
    </Container>
  )
}
function TrendingCollection() {
  const [boxTypeHovering, setboxTypeHovering] = useState('')
  return (
    <Container maxW="1280px" px="1rem">
      <Text className="fs--26 fw--600" color="white">
        Trending Collections
      </Text>
      <GradientDividers />
      <Stack direction={{ base: 'column', md: 'row' }} mt="20px">
        {/* Hover Collection Card Start */}

        <Box
          as={Flex}
          alignItems="end"
          m="0px"
          px="10px"
          w="100%"
          h="360px"
          rounded="20px"
          backgroundImage={`url('${NTR1}')`}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          onMouseEnter={() => {
            setboxTypeHovering('main')
          }}
          onMouseLeave={() => {
            setboxTypeHovering('')
          }}
        >
          {/* Hover collection card start */}
          {boxTypeHovering == 'main' ? (
            <TrendingHoverCard
              pl="30px"
              pr="18"
              mb="20px"
              height="120px"
              logoBoxSize="62"
              textClass="fs--18 fw--700"
              ml="20px"
              mr="12"
              boxType="main"
            />
          ) : null}
          {/* Hover Collection card end */}
        </Box>
        <Flex
          m={0}
          // px="10px"
          direction={{ base: 'row', md: 'column' }}
          gap="5px"
          w="100%"
          // height="360px"
          align="start"
        >
          {/*  */}
          <Box
            as={Flex}
            alignItems="end"
            rounded="20px"
            w="100%"
            h="175px"
            backgroundImage={`url('${NTR1}')`}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            px="7px"
            onMouseEnter={() => {
              setboxTypeHovering('c1')
            }}
            onMouseLeave={() => {
              setboxTypeHovering('')
            }}
          >
            {/* hover box start */}
            {boxTypeHovering == 'c1' ? (
              <TrendingHoverCard
                pl="15px"
                pr="9"
                mb="5px"
                height="60px"
                logoBoxSize="31px"
                textClass="fs--10 fw--700"
                ml="10px"
                mr="3"
              />
            ) : null}
            {/* hover box end */}
          </Box>
          <Box
            as={Flex}
            alignItems="end"
            rounded="20px"
            m={0}
            p={0}
            mt={{ base: '0px', md: '10px' }}
            w="100%"
            h="175px"
            backgroundImage={`url('${NTR1}')`}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            px="7px"
            onMouseEnter={() => {
              setboxTypeHovering('c2')
            }}
            onMouseLeave={() => {
              setboxTypeHovering('')
            }}
          >
            {boxTypeHovering == 'c2' ? (
              <TrendingHoverCard
                pl="15px"
                pr="9"
                mb="5px"
                height="60px"
                logoBoxSize="31px"
                textClass="fs--10 fw--700"
                ml="10px"
                mr="3"
              />
            ) : null}
          </Box>
        </Flex>

        <Box
          as={Flex}
          alignItems="end"
          rounded="20px"
          m={0}
          p={0}
          h="360px"
          backgroundImage={`url('${NTR1}')`}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          w="100%"
          px="7px"
          onMouseEnter={() => {
            setboxTypeHovering('c3')
          }}
          onMouseLeave={() => {
            setboxTypeHovering('')
          }}
        >
          {boxTypeHovering == 'c3' ? (
            <TrendingHoverCard
              pl="15px"
              pr="9"
              mb="5px"
              height="60px"
              logoBoxSize="31px"
              textClass="fs--10 fw--700"
              ml="10px"
              mr="3"
            />
          ) : null}
        </Box>
      </Stack>
    </Container>
  )
}
type props = {
  title: string
}

// slider previous button start
function TopNftTending({ title }: props) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // centerMode: true,
    // centerPadding: '56px',
    initialSlide: 0,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }
  const navigate = useNavigate()

  return (
    <Container maxW="1280px" px="1rem">
      <Text className="fs--24 fw--600" color="white">
        {title}
      </Text>
      <GradientDividers />
      <Box mt="20px" position="relative" mx="auto">
        <Slider {...settings}>
          {[0, 1, 2, 3, 4, 5].map(item => {
            return (
              <SliderCollectionCard
                key={item}
                onclick={() => navigate('/view-NFT')}
              />
            )
          })}
        </Slider>
      </Box>
    </Container>
  )
}

function ExploreClubs() {
  const [activeSlideIndex, setactiveSlide] = useState<number>()
  const sliderRef = useRef<Slider>(null)

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef?.current?.slickNext()
    }
  }
  const goTopreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef?.current.slickPrev()
    }
  }
  const slideCount = sliderRef?.current?.props?.children
  console.log('slider count', sliderRef?.current?.props?.children)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (current: number) => {
      console.log('currrrrent slide', current)
      setactiveSlide(current)
    },
  }

  return (
    <Box>
      <Box
        height="100vh"
        display={{ base: 'none', md: 'block' }}
        w="100%"
        backgroundImage={`url('${ExploreBg}')`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <Container
          textAlign="center"
          pb="30px"
          mt="2rem"
          maxW="1280px"
          px="1rem"
          alignSelf="center"
        >
          <Text className="fs--36 fw--700" mb="15px" color="white">
            Explore Clubs
          </Text>
          <Text color="text" maxW="1100px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          </Text>

          <Slider ref={sliderRef} {...settings}>
            {clubData?.map(item => {
              return (
                <ExploreClubCard
                  activeSlideIndex={activeSlideIndex}
                  sliderCount={slideCount}
                  data={item}
                  key={item.id}
                />
              )
            })}
          </Slider>
          <Flex align="center" pt="20px" justify="center">
            <Button
              rounded="full"
              bg="black"
              boxSize="50px"
              onClick={goTopreviousSlide}
            >
              <ChevronLeftIcon fontSize="25px" />
            </Button>
            <Button
              bg="black"
              rounded="full"
              opacity=".25"
              boxSize="85px"
              mx="10px"
            >
              See all
            </Button>
            <Button
              rounded="full"
              bg="black"
              boxSize="50px"
              onClick={goToNextSlide}
            >
              <ChevronRightIcon fontSize="25px" />
            </Button>
          </Flex>
        </Container>
      </Box>

      <Box
        color="white"
        className="tokenImage"
        h="100vh"
        bgPosition={{ base: 'left top, right bottom', md: 'left,right' }}
      >
        <Stack direction={{ base: 'column', lg: 'row' }} alignItems="center">
          <Box maxW={{ base: '100%', md: '50%' }} px="5rem" pt="13rem">
            <Text className="fs--34 fw--600" color="white">
              The First Exchange Token Built on a Bitcoin Sidechain
            </Text>
            <Text mt="30px" mb="40px" color="text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi
            </Text>
            <Button w="156px" size="lg" className="fs--14 fw--400">
              Exchange
            </Button>
          </Box>
          <Image
            src={tokenImg}
            alt="tokenimg"
            boxSize={{ base: '400px', md: '600px' }}
            display={{ base: 'none', lg: 'block' }}
          />
        </Stack>
      </Box>
    </Box>
  )
}
// Explore Club Card start { data, }: {data: ClubCard}
function ExploreClubCard({ data, activeSlideIndex }: any) {
  // const previousSlideIndex =
  //   activeSlideIndex !== 0 ? activeSlideIndex - 1 : slideCount - 1
  // const nextSlideIndex =
  //   activeSlideIndex !== slideCount - 1 ? activeSlideIndex + 1 : 0
  return (
    <Flex
      mt="5rem"
      mb="15px"
      className="gradient-club-card-border"
      transform={activeSlideIndex ? 'skew(0deg,351deg)' : 'skew(0deg,-355deg)'}
      // transform={
      //   activeSlideIndex > previousSlideIndex
      //     ? 'skew(0deg,351deg)'
      //     : activeSlideIndex < nextSlideIndex
      //     ? 'skew(0deg,-355deg)'
      //     : ''
      // }
      position="relative"
      direction="column"
      padding="15px"
      justifyContent="space-between"
      alignItems="center"
      width="235px"
      height="365px"
      rounded="20px"
      bg="rgb(33,36,44)"
    >
      <Box
        filter="blur(30px)"
        w="80%"
        height="55%"
        mt="15px"
        padding="10px"
        rounded="full"
        background="radial-gradient(#4F81FD,  #C03DFD)"
      ></Box>
      <Image
        height="45%"
        position="absolute"
        top="35%"
        left="50%"
        transform="translate(-50%, -50%)"
        zIndex="100"
        src={data?.clubLogourl}
      />
      <Flex
        p="15px"
        align="center"
        justify="center"
        rounded="10px"
        height="80px"
        bg="rgb(37,41,79)"
        width="90%"
      >
        <Text className="fs--14 fw--600" color="white" textAlign="center">
          {data?.clubName}
        </Text>
      </Flex>
    </Flex>
  )
}
// Explore Club Card end
function ProfessionalAthlete() {
  const [activeSlide, setactiveSlide] = useState(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    // centerMode: true,
    // centerPadding: '60px',
    arrow: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (next: any) => {
      setactiveSlide(next)
    },
  }
  const sliderRef = useRef<Slider>(null)
  const goToNextSlide = () => {
    // console.log(sliderRef);
    if (sliderRef.current) {
      sliderRef?.current?.slickNext()
    }
  }
  const goTopreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef?.current.slickPrev()
    }
  }
  return (
    <Container
      alignSelf="center"
      maxW="1280px"
      py="50px"
      as={Flex}
      flexDirection="column"
      justifyContent="center"
      mb="50px"
      mt="50px"
    >
      <Box textAlign="center">
        <Heading className="fs--34 fw--600" color="white">
          Professional Athletes
        </Heading>
        <Text mt="30px" mb="40px" color="text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi
        </Text>
      </Box>
      <Slider ref={sliderRef} {...settings}>
        {EthleteData.map((item, index) => {
          return (
            <Box
              flexWrap="wrap"
              height="371px"
              transform={activeSlide == index ? 'scale(.97)' : 'scale(.80)'}
              key={item?.athletePowerunit}
              w="100%"
            >
              <ProfessionalAthleteCard key={item?.athleteImgUrl} />
            </Box>
          )
        })}
      </Slider>
      {/* Slider will end here */}
      <Flex align="center" pt="0px" justify="center" mt="2rem">
        <Button rounded="full" boxSize="50px" onClick={goTopreviousSlide}>
          <ChevronLeftIcon fontSize="25px" color="black" />
        </Button>
        <Button
          bg="black"
          rounded="full"
          opacity=".25"
          boxSize="85px"
          mx="10px"
        >
          See all
        </Button>
        <Button rounded="full" boxSize="50px" onClick={goToNextSlide}>
          <ChevronRightIcon fontSize="25px" color="black" />
        </Button>
      </Flex>
    </Container>
  )
}
// Trending collection hover card start
type hoveringCardProps = {
  pl: string
  pr: string
  mb: string
  height: string
  logoBoxSize: string
  textClass: string
  ml: string
  mr: string
  boxType?: string
}
function TrendingHoverCard({
  pl,
  pr,
  mb,
  height,
  logoBoxSize,
  textClass,
  ml,
  mr,
  boxType,
}: hoveringCardProps) {
  return (
    <Flex
      justifyContent="space-between"
      align="center"
      pl={pl}
      pr={pr}
      bg="black"
      opacity=".85"
      w="100%"
      mb={mb}
      height={height}
      rounded="14px"
    >
      <Flex align="center">
        <Image
          borderRadius="full"
          boxSize={logoBoxSize}
          src="https://bit.ly/dan-abramov"
          alt="Dan Abramov"
        />
        <Text className={textClass} ml={ml} mr={mr} color="white">
          NFT FAN TOKENS
        </Text>
        <TiStarburst style={{ fill: 'url(#blue-gradient)' }} />
        <svg width="0" height="0">
          <linearGradient
            id="blue-gradient"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="0%"
          >
            <stop stopColor="#7a6ded" offset="0%" />
            <stop stopColor="#591885" offset="100%" />
          </linearGradient>
        </svg>
      </Flex>
      {boxType == 'main' ? (
        <Text color="white" className="fs--18">
          325,652,54{' '}
          <Text as="span" className="fs--12">
            SAR
          </Text>
        </Text>
      ) : null}
    </Flex>
  )
}
// Trending collection hover end
