import { ChevronLeftIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import React from 'react'

export default function SliderPrevArrow(props: any) {
  const { onClick } = props
  return (
    <IconButton
      aria-label="back"
      className="arrow nextarrow"
      rounded="full"
      position="absolute"
      left={{ base: '-7px', sm: '-20px', md: '-70px' }}
      top="50%"
      onClick={onClick}
    >
      <ChevronLeftIcon fontSize="25px" color="black" />
    </IconButton>
  )
}
