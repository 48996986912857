import React, { useState, useRef } from 'react'
// import Header from 'components/header'
// import Footer from 'components/footer'
import { BiSearch } from 'react-icons/bi'

import { Box, Text, Container, HStack, Flex, Stack } from '@chakra-ui/layout'
import { Input, Button, InputGroup, InputLeftElement } from '@chakra-ui/react'
import GradientDividers from 'components/GradientsDevider'
import { clubData } from 'utils/data/landingpageData'
import { ExploreClubCard } from 'components/exploreClubCard'
import ProfessionalAthleteCard from 'components/professionalAthleteCard'
import { AthleteCard } from 'utils/types/tandingPage.Types'
import { EthleteData } from 'utils/data/landingpageData'
import Slider from 'react-slick'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import Header from 'components/header'
import MainBannerFootbal from 'assets/images/my-club-bg.png'

export default function Clubs() {
  const [activeSlide, setactiveSlide] = useState(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    arrow: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (next: any) => {
      setactiveSlide(next)
    },
  }
  const sliderRef = useRef<Slider>(null)
  const goToNextSlide = () => {
    // console.log(sliderRef);
    if (sliderRef.current) {
      sliderRef?.current?.slickNext()
    }
  }
  const goTopreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef?.current.slickPrev()
    }
  }
  return (
    <Box
      bgImage={MainBannerFootbal}
      bgSize="cover"
      bgRepeat="no-repeat"
      objectFit="contain"
      h="100%"
    >
      <Container maxW="1280px" px="1rem">
        <Header />
        <Text className="fs--26" mb="22px" color="white">
          Sport Clubs{' '}
          <Text color="text" as="span">
            (24)
          </Text>
        </Text>
        <HStack align="center">
          <InputGroup bg="inputbg" rounded="10px" size="lg">
            <InputLeftElement>
              <BiSearch
                style={{ fontSize: '20px', fill: 'url(#blue-gradient)' }}
              />{' '}
              <svg width="0" height="0">
                <linearGradient
                  id="blue-gradient"
                  x1="100%"
                  y1="100%"
                  x2="0%"
                  y2="0%"
                >
                  <stop stopColor="#7a6ded" offset="0%" />
                  <stop stopColor="#591885" offset="100%" />
                </linearGradient>
              </svg>
            </InputLeftElement>
            <Input
              variant="filled"
              bg="inputbg"
              placeholder="Search"
              borderColor="border"
              color="text"
              _hover={{}}
            />
          </InputGroup>

          <Button variant="outline" size="lg" width={{ md: '174px' }}>
            Search
          </Button>
        </HStack>
        <GradientDividers />
        <Flex
          flexWrap="wrap"
          align="center"
          justify="center"
          gap={{ base: '5px', md: '30px' }}
        >
          {clubData.map(item => {
            return <>{item.id <= 5 ? <ExploreClubCard data={item} /> : null}</>
          })}
        </Flex>
        <Flex align="center" mt="52px" justify="center" border="0px solid red">
          <Button className="fs--14" width="142px" height="40px">
            View All
          </Button>
        </Flex>
        {/* Professional athlete Start */}
        <Stack border="0px solid red" mt="70px">
          <Text color="white" className="fs--26 fw-700" mb="22px">
            Professional Athletes
          </Text>
          <GradientDividers />
          <Box
            alignSelf="center"
            w="90%"
            py="50px"
            as={Flex}
            flexDirection="column"
            justifyContent="center"
            mb="50px"
            mt="50px"
          >
            {/* Slider will be place here */}
            <Slider ref={sliderRef} {...settings}>
              {EthleteData.map((item: AthleteCard, index: any) => {
                return (
                  <Box
                    flexWrap="wrap"
                    height="371px"
                    transform={
                      activeSlide == index ? 'scale(.97)' : 'scale(.80)'
                    }
                    key={item?.athletePowerunit}
                    w="100%"
                  >
                    <ProfessionalAthleteCard />
                  </Box>
                )
              })}
            </Slider>
            {/* Slider will end here */}
            <Flex align="center" pt="0px" justify="center">
              <Button rounded="full" boxSize="50px" onClick={goTopreviousSlide}>
                <ChevronLeftIcon fontSize="25px" color="black" />
              </Button>
              <Button
                bg="black"
                rounded="full"
                opacity=".25"
                boxSize="85px"
                mx="10px"
              >
                See all
              </Button>
              <Button rounded="full" boxSize="50px" onClick={goToNextSlide}>
                <ChevronRightIcon fontSize="25px" color="black" />
              </Button>
            </Flex>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}
