import React from 'react'
import { Text, Flex, Image, Stack, Button } from '@chakra-ui/react'
import cardIcon from 'assets/images/CardIcon.png'
import CoverImg from 'assets/images/cardImg.png'
// import CoverLogo from 'assets/images/CardLogo.png'
type props = {
  btnType?: string | 'Buy'
  onclick: () => void
}
export default function SliderCollectionCard({ btnType, onclick }: props) {
  return (
    <Stack
      className="hoverCard"
      bg="#131623"
      h="370px"
      w="261px"
      mt="50px"
      p="18px"
      mx="auto"
    >
      <Flex color="white" justify="space-between">
        <Text color="primary.400" className="fs--14">
          NFT fan club
        </Text>
        <Flex align="center">
          <Image boxSize="12px" src={cardIcon} />
          <Text ml="5px" className="fs--12">
            451
          </Text>
        </Flex>
      </Flex>
      <Image mb="16px" rounded="8px" h="176px" src={CoverImg} />
      <Text mb="17px" color="text" className="fs--12">
        Mintable shared football store
      </Text>
      <Flex mb="17px" className="fs--14" justify="space-between">
        <Text className="fw--700" color="white">
          NFT FAN TOKENS
        </Text>
        <Text className="fw--700" color="white">
          #21
        </Text>
      </Flex>
      <Flex mb="17px" className="fs--12" color="text" justify="space-between">
        <Text>32562554 SAR</Text>
        <Text>($36524)</Text>
      </Flex>
      <Flex color="white" align="center">
        {/* CoverImg CoverLogo*/}
        <Image
          rounded="full"
          border="0px solid red"
          boxSize="36px"
          src={CoverImg}
        />
        <Text className="fs--12" ml="10px">
          Pixadeplayersnft
        </Text>
      </Flex>
      <Button className="customBoxButton fs--12" onClick={onclick}>
        {btnType ? 'Resale' : 'Buy'}
      </Button>
    </Stack>
  )
}
