import React from 'react'
import {
  Box,
  Container,
  Text,
  Stack,
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Image,
} from '@chakra-ui/react'
import ClubBanner from 'assets/images/ClubBanner.png'
import ClubMain from 'assets/images/ClubMainImg.png'
import Header from 'components/header'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import ClubCollectionTab from './clubCollection'
import ClubSocialMedia from './socialMedia'

export default function SpeciificClub() {
  return (
    <Box>
      <Header />
      <Stack
        backgroundImage={`url(${ClubMain})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        direction={['column', 'row']}
        bgSize="100%"
        h="80vh"
      >
        <Container
          maxW="1280px"
          px="1rem"
          display={{ base: 'block', md: 'flex' }}
          justifyContent="space-between"
          alignItems="center"
          pos="relative"
        >
          <Box maxW="493px" zIndex={11} mt={{ base: '3rem', sm: 'none' }}>
            <Text
              mb={{ base: '10px', sm: '20px' }}
              color="white"
              fontSize={{ base: '40px', md: '54px' }}
              fontWeight={700}
            >
              NFT{' '}
              <Text
                color="primary.400"
                as="span"
                fontSize={{ base: '40px', md: '54px' }}
                fontWeight={700}
              >
                FAN
              </Text>{' '}
              CLUB
            </Text>
            <Text mb="20px" className="fs--14">
              Ac feugiat sed lectus vestibulum mattis ullamcorper. Ultrices
              tincidunt arcu non sodales neque sodales ut. Elementum nibh tellus
              molestie nunc.
            </Text>
            <Button w="170px" className="fs--14 fw--400">
              Explore <ArrowForwardIcon ml="15px" />
            </Button>
          </Box>
          <Image src={ClubBanner} pos="absolute" right="0" />
        </Container>
      </Stack>
      <Box mt={{ base: '10rem', md: '25px' }} pt="15px">
        <Tabs isFitted variant="unstyled">
          <TabList mb="1em" mx="auto" justifyContent="center">
            <Box
              h="49px"
              rounded="29px"
              display="flex"
              width="686px"
              bg="#181E30"
            >
              <Tab
                color="#99ACD2"
                _selected={{
                  bg: 'linear-gradient(94deg, #C03DFD 0%, #4F81FD 100%)',
                  color: 'white',
                }}
                rounded="29px"
              >
                Collections
              </Tab>
              <Tab
                color="#99ACD2"
                _selected={{
                  bg: 'linear-gradient(94deg, #C03DFD 0%, #4F81FD 100%)',
                  color: 'white',
                }}
                rounded="29px"
              >
                Social Media
              </Tab>
            </Box>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ClubCollectionTab />
            </TabPanel>
            <TabPanel>
              <ClubSocialMedia />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}
