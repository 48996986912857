import React from 'react'
import {
  Container,
  Image,
  Button,
  Flex,
  Box,
  useMediaQuery,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import mainLogo from 'assets/icons/login/logo.svg'
import { useNavigate } from 'react-router-dom'
import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  SettingsIcon,
} from '@chakra-ui/icons'
import { useGetUserLogin } from 'utils/hooks'
import { LogoutIcon } from 'assets/icons'
// import {ChevronDownIcon} from '@chakra-ui/icons'
export default function Header() {
  const buttons = [
    { name: 'Collections', path: '/collections' },
    { name: 'Explore NFT', path: '/explore' },
    { name: 'Clubs', path: '/clubs' },
    { name: 'Athletes', path: '/clubs' },
  ]
  const navigate = useNavigate()
  const [isLargerThan960] = useMediaQuery('(min-width: 960px)')
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      {isLargerThan960 ? (
        <Container
          display="flex"
          alignItems="center"
          maxW="1280px"
          py="20px"
          px="0"
        >
          <Box flex={0.4}>
            {' '}
            <Image
              mt="7px"
              h="50px"
              src={mainLogo}
              onClick={() => navigate('/')}
              cursor="pointer"
            />
          </Box>

          <Flex justify="space-between" flex={0.7}>
            <Flex gap="2rem">
              {buttons.map((btn, index) => (
                <Button
                  key={index}
                  color="text"
                  variant="ghost"
                  className="fs--14 fw--400"
                  onClick={() => navigate(btn?.path)}
                >
                  {btn.name}
                </Button>
              ))}
            </Flex>
            {!useGetUserLogin() ? (
              <Button
                variant="outline"
                className="fs--14 fw--400"
                mx="0"
                onClick={() => navigate('/login')}
              >
                Sign In
              </Button>
            ) : (
              <Menu matchWidth={true}>
                <MenuButton
                  w="120px"
                  as={Button}
                  variant="outlineltr"
                  rightIcon={<ChevronDownIcon />}
                >
                  Profile
                </MenuButton>
                <MenuList minW={'120px'} bg="#131623" borderColor="border">
                  <MenuItem
                    as={Button}
                    variant="ghost"
                    bg="transparent"
                    color="text"
                    className="fs--14"
                    leftIcon={<SettingsIcon />}
                    onClick={() => navigate('/my-account')}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    as={Button}
                    leftIcon={<LogoutIcon />}
                    variant="ghost"
                    className="fs--14"
                    bg="transparent"
                    color="text"
                    onClick={() => {
                      navigate('/login')
                      localStorage.removeItem('access_token')
                    }}
                  >
                    Log Out
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Container>
      ) : (
        <>
          <HStack justify="space-between" p="10px">
            <IconButton
              bg="transparent"
              aria-label="drawer"
              icon={<HamburgerIcon fontSize="25px" color="#c03dfd" />}
              onClick={onOpen}
            />
            <Image
              mt="7px"
              h="50px"
              p="10px"
              src={mainLogo}
              onClick={() => navigate('/')}
              cursor="pointer"
            />
            {!useGetUserLogin() ? (
              <Button
                variant="outline"
                className="fs--14 fw--400"
                mx="0"
                w="80px"
                onClick={() => navigate('/login')}
              >
                Sign In
              </Button>
            ) : (
              <Menu matchWidth={true}>
                <MenuButton w="85px" as={Button} px="0" variant="outlineltr">
                  Profile <ChevronDownIcon />
                </MenuButton>
                <MenuList minW={'80px'} bg="#131623" borderColor="border">
                  <MenuItem
                    as={Button}
                    variant="ghost"
                    bg="transparent"
                    color="text"
                    className="fs--12"
                    leftIcon={<SettingsIcon />}
                    onClick={() => navigate('/my-account')}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    as={Button}
                    leftIcon={<LogoutIcon />}
                    variant="ghost"
                    className="fs--12"
                    bg="transparent"
                    color="text"
                    onClick={() => {
                      navigate('/login')
                      localStorage.removeItem('access_token')
                    }}
                  >
                    Log Out
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
          <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="xs">
            <DrawerOverlay />
            <DrawerContent bg="transparent" className="glass-effect-drawer">
              <DrawerHeader py="0">
                <HStack justify="space-between">
                  <Image
                    mt="7px"
                    h="50px"
                    p="10px"
                    src={mainLogo}
                    onClick={() => navigate('/')}
                    cursor="pointer"
                  />
                  <IconButton
                    onClick={onClose}
                    size="xs"
                    aria-label="closebtn"
                    icon={<CloseIcon fontSize="10px" />}
                    isRound
                  />
                </HStack>
              </DrawerHeader>
              <DrawerBody>
                {buttons.map((btn, index) => (
                  <Button
                    display="block"
                    key={index}
                    color="text"
                    variant="ghost"
                    className="fs--14 fw--400"
                    onClick={() => navigate(btn?.path)}
                  >
                    {btn.name}
                  </Button>
                ))}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  )
}
