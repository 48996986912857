import React from 'react'
import { Stack } from '@chakra-ui/react'
import { Routes, Route, Navigate } from 'react-router-dom'
import SingleCollection from 'views/SingleCollection'
import HomePage from 'views/homepage'
import Collection from 'views/collections'
import ViewNFT from 'views/collections/viewNFT'
import MyAccount from 'views/myAccount'
import SpeciificClub from 'views/SpecificClub'
import Clubs from 'views/clubs'
import Login from 'views/login'
import PrivateRoute from './privateRoute'
const routes = [
  {
    path: '/',
    Component: HomePage,
  },
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/single-collection/:id',
    Component: SingleCollection,
  },
  {
    path: '/collections',
    Component: Collection,
  },
  {
    path: '/view-NFT',
    Component: ViewNFT,
  },
  {
    path: '/athletes',
    Component: Clubs,
  },
  {
    path: '/my-account',
    Component: MyAccount,
    isPrivate: true,
  },
  {
    path: '/specific-club',
    Component: SpeciificClub,
  },
  {
    path: '/clubs',
    Component: Clubs,
  },
]
export default function AppRoutes() {
  return (
    <Stack>
      <Routes>
        {routes?.map(({ path, Component, isPrivate }) => (
          <Route
            key={path}
            path={path}
            element={
              isPrivate ? (
                <PrivateRoute>
                  <Component />
                </PrivateRoute>
              ) : (
                <Component />
              )
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Stack>
  )
}
