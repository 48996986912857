import React, { useState } from 'react'

import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
} from '@chakra-ui/layout'
import {
  Avatar,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useMediaQuery,
} from '@chakra-ui/react'
import { RxCross2 } from 'react-icons/rx'
import { createColumnHelper } from '@tanstack/react-table'
import { ICollections } from 'utils/types/collections.type'
import DataTable from 'components/table'
import Pagination from 'components/pagination'
import { VectorDown } from 'assets/icons'
import Header from 'components/header'
import { BiSearch } from 'react-icons/bi'
import coverBgImage from 'assets/images/myAccBgImg.png'
import { useNavigate } from 'react-router-dom'
import { useGetAllCollection } from 'utils/apis/collections.api'

const columnHelper = createColumnHelper<ICollections>()
const columns = [
  columnHelper.accessor('logoImageUrl', {
    cell: info => (
      <Flex alignItems="center" gap="15px" className="fs--14">
        <Avatar
          name="Christian Nwamba"
          src={info?.row?.original?.logoImageUrl}
          className="gradient-border-mask"
          as={Link}
          href={`/single-collection/${info.row.original.id}`}
        />
        <Box>
          <Text color="white">{info?.row?.original?.collectionName}</Text>
          <Text color="text">{info?.row?.original?.club?.name}</Text>
        </Box>
      </Flex>
    ),
    header: 'Collections',
  }),
  columnHelper.accessor('totalNfts', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info.getValue()} SAR
      </Text>
    ),
    header: 'Volume',
  }),
  columnHelper.accessor('saleVolumePercentage', {
    cell: info => (
      <Text color="green" className="fs--14">
        +{info?.getValue()}
      </Text>
    ),
    header: '% Volume',
  }),
  columnHelper.accessor('totalSold', {
    cell: info => (
      <Text color="white" className="fs--14">
        {info?.getValue()}
      </Text>
    ),

    header: 'Sale',
  }),

  columnHelper.accessor('floorPrice', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()} SAR
      </Text>
    ),
    header: 'Floor Price',
  }),
  columnHelper.accessor('totalOwners', {
    cell: info => (
      <Text color="white" className="fs--14">
        {info?.getValue()}k
      </Text>
    ),
    header: 'Owners',
  }),
  columnHelper.accessor('totalSupply', {
    cell: info => (
      <Text color="white" className="fs--14">
        {info?.getValue()}k
      </Text>
    ),
    header: 'Total Supply',
  }),
]
const mobileViewCols = [
  columnHelper.accessor('logoImageUrl', {
    cell: info => (
      <Flex alignItems="center" gap="15px" className="fs--14">
        {/* <Avatar
          name="Christian Nwamba"
          src={info?.row?.original?.image}
          className="gradient-border-mask"
        /> */}
        <Box as={Link} href={`/single-collection/${info.row.original.id}`}>
          <Text color="white">{info?.row?.original?.collectionName}</Text>
          <Text color="text">{info?.row?.original?.club?.name}</Text>
        </Box>
      </Flex>
    ),
    header: 'Collections',
  }),
  columnHelper.accessor('totalNfts', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info.getValue()} SAR
      </Text>
    ),
    header: 'Volume',
  }),
  columnHelper.accessor('totalSupply', {
    cell: info => (
      <Text color="white" className="fs--14">
        {info?.getValue()}k
      </Text>
    ),
    header: 'Total Supply',
  }),
]
export default function Collection() {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [isLargerThan768] = useMediaQuery('(min-width: 769px)')
  const navigate = useNavigate()
  const { data: res } = useGetAllCollection(0, 10)
  return (
    <Box
      bgImage={coverBgImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <Container maxW="1280px" px="1rem" h="100vh">
        <Header />
        <Heading className="fs--26px" color="white" mb="26px">
          Popular Collections
        </Heading>
        <Box display={{ base: 'flex' }} gap="10px">
          <InputGroup bg="inputbg" rounded="10px" size="lg">
            <InputLeftElement>
              <BiSearch
                style={{ fontSize: '20px', fill: 'url(#blue-gradient)' }}
              />{' '}
              <svg width="0" height="0">
                <linearGradient
                  id="blue-gradient"
                  x1="100%"
                  y1="100%"
                  x2="0%"
                  y2="0%"
                >
                  <stop stopColor="#7a6ded" offset="0%" />
                  <stop stopColor="#591885" offset="100%" />
                </linearGradient>
              </svg>
            </InputLeftElement>
            <Input
              variant="filled"
              bg="inputbg"
              placeholder="Search"
              borderColor="border"
              color="text"
              _hover={{}}
            />
            <InputRightElement>
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <IconButton
                    aria-label="btn"
                    variant="ghost"
                    icon={<VectorDown />}
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    w="container.lg"
                    bg="inputbg"
                    borderColor="border"
                  >
                    <PopoverBody color="text" p="22px">
                      Select collections which match{' '}
                      <Button
                        // variant="outline
                        p="0"
                        borderRadius="full"
                      >
                        all
                      </Button>
                      <Button
                        // variant="outline
                        bg="blackAlpha.400"
                        p="0"
                        borderRadius="full"
                      >
                        any
                      </Button>
                      of the following conditions:
                      {showFilter && (
                        <HStack w="90%" my="1.4rem" transition="ease-in-out">
                          <Input
                            placeholder="Volume"
                            variant="input"
                            flex={0.4}
                          />
                          <Input
                            placeholder="Greater than"
                            variant="input"
                            flex={0.4}
                          />
                          <Input placeholder="0" variant="input" flex={0.2} />
                          <IconButton
                            bg="#161D30"
                            border="1px solid gray"
                            aria-label="Search database"
                            icon={<RxCross2 />}
                            isRound
                            onClick={() => setShowFilter(false)}
                          />
                        </HStack>
                      )}
                      <Box>
                        <Button
                          variant="outline"
                          onClick={() => setShowFilter(true)}
                        >
                          Add Filter
                        </Button>{' '}
                        <Button>Search</Button>
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </InputRightElement>
          </InputGroup>
          <Button
            variant="outlineltr"
            color="text"
            size="lg"
            onClick={() => navigate('/viewNFT')}
          >
            Last 24 Hours
          </Button>
        </Box>
        <Box
          border="2px solid #2F3852"
          rounded="10px"
          mt="10px"
          p="10px"
          bg="tableBg"
        >
          <DataTable
            columns={isLargerThan768 ? columns : mobileViewCols}
            data={res || []}
          />
          <Flex justifyContent={{ base: 'center', md: 'end' }}>
            <Pagination />
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}
