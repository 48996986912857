import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
export default function SliderNextArrow(props: any) {
  const { onClick } = props
  return (
    <IconButton
      aria-label="next"
      className="arrow nextarrow"
      rounded="full"
      position="absolute"
      right={{ base: '-10px', sm: '-20px', md: '-70px' }}
      top="50%"
      onClick={onClick}
    >
      <ChevronRightIcon fontSize="25px" color="black" />
    </IconButton>
  )
}
