import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Button, IconButton } from '@chakra-ui/react'
import React, { useState } from 'react'
export default function Pagination() {
  const [activePage, setActivePage] = useState<number>(2)

  return (
    <Box
      // className="glass-effect"
      bg="#232C47"
      w="max-content"
      rounded="full"
      p="2px 0"
      // ml="auto"
    >
      <IconButton
        aria-label="leftchevron"
        icon={<ChevronLeftIcon />}
        isRound
        size="sm"
        mr="24px"
        bg="#2D354E"
        colorScheme="secondary"
      />
      {[1, 2, 3, 4].map(btn => (
        <Button
          key={btn}
          rounded="50%"
          size="sm"
          mx="2px"
          p="0"
          variant={btn === activePage ? 'primary' : ''}
          onClick={() => setActivePage(btn)}
          className="fs--14 fw--400"
        >
          {btn}
        </Button>
      ))}
      <IconButton
        aria-label="leftchevron"
        icon={<ChevronRightIcon />}
        isRound
        size="sm"
        ml="24px"
        bg="#2D354E"
        colorScheme="secondary"
      />
    </Box>
  )
}
