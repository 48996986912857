import { AllInvoices } from 'utils/types/collections.type'
import cardImg from 'assets/images/cardImg.png'

export const allInvoices: AllInvoices[] = [
  {
    image: cardImg,
    NFTID: 'Nft-F3003004',
    invoiceID: 'Inv-S220006',
    date: '13-04-2022',
    amount: 34532,
  },
  {
    image: cardImg,
    NFTID: 'Nft-F56300304',
    invoiceID: 'Inv-S220006',
    date: '13-04-2022',
    amount: 34532,
  },
  {
    image: cardImg,
    NFTID: 'Nft-F56300304',
    invoiceID: 'Inv-S220006',
    date: '13-04-2022',
    amount: 34532,
  },
  {
    image: cardImg,
    NFTID: 'Nft-F56300304',
    invoiceID: 'Inv-S220006',
    date: '13-04-2022',
    amount: 34532,
  },
  {
    image: cardImg,
    NFTID: 'Nft-F6300304',
    invoiceID: 'Inv-S220006',
    date: '13-04-2022',
    amount: 34532,
  },
  {
    image: cardImg,
    NFTID: 'Nft-F56300304',
    invoiceID: 'Inv-S220006',
    date: '13-04-2022',
    amount: 34532,
  },
]
