import React from 'react'
import { Navigate } from 'react-router-dom'
import { useGetUserLogin } from 'utils/hooks'

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  if (useGetUserLogin()) {
    return children
  }
  return <Navigate to="/login" />
}
