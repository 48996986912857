import { useMutation, useQuery } from '@tanstack/react-query'
import { IforgetPassword, ILogin } from 'utils/types/auth.type'
import { client } from './api-client'

export const useSignUp = () =>
  useMutation((data: unknown) =>
    client('auth/userSignup', {
      data,
    }),
  )

export const useGetLoggedIn = () =>
  useQuery<unknown, Error>(
    ['useLoggedIn'],
    () => client('auth/getLoggedInUser'),
    // {
    //   enabled: !!id,
    // },
  )
export const useLogin = () =>
  useMutation<{ id: string; token: string }, Error, ILogin>(data =>
    client('auth/userSignin', {
      data,
    }),
  )
export const useForgetPassword = () =>
  useMutation<unknown, Error, IforgetPassword>(data =>
    client('auth/forgotPassword', { data }),
  )
export const useVerifyOTP = () =>
  useMutation<{ token: string }, Error, IforgetPassword>(data =>
    client('auth/verifyOtpForResetPassword', { data }),
  )
export const useChangePassword = (token: string) =>
  useMutation<unknown, Error, string>(password =>
    client('auth/resetPassword', {
      data: { password },
      headers: { Authorization: token },
    }),
  )
