import { ClubCard,AthleteCard } from "utils/types/tandingPage.Types";
import Club1 from 'assets/images/exploreClub1.png'
import athleteLogo from "assets/images/AthleteLogo.png"
import athlete from "assets/images/Athlete.png"
export const clubData:ClubCard[] =[
    {
        id:0,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:1,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:2,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:3,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:4,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:5,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:6,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:7,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:8,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    },
    {
        id:9,
        clubName:'ALMUJAZZAL CLUB',
        clubLogourl:Club1
    }
] 

export const EthleteData:AthleteCard[]=[
    { 
       id:0,
       athleteImgUrl:athlete,
       athletePowerunit:'106',
       athleteLogoUrl:athleteLogo,
       athleteName:'Rakuten',
       athleteLevel:'LVL 1'
    },
    {
       id:1,
       athleteImgUrl:athlete,
       athletePowerunit:'107',
       athleteLogoUrl:athleteLogo,
       athleteName:'Rakuten',
       athleteLevel:'LVL 1'
    },
    { 
       id:2,
       athleteImgUrl:athlete,
       athletePowerunit:'108',
       athleteLogoUrl:athleteLogo,
       athleteName:'Rakuten',
       athleteLevel:'LVL 1'
    },
    {
        id:3,
       athleteImgUrl:athlete,
       athletePowerunit:'109',
       athleteLogoUrl:athleteLogo,
       athleteName:'Rakuten',
       athleteLevel:'LVL 1'
    },
    {
        id:4,
       athleteImgUrl:athlete,
       athletePowerunit:'110',
       athleteLogoUrl:athleteLogo,
       athleteName:'Rakuten',
       athleteLevel:'LVL 1'
    },
    {
       id:4,
       athleteImgUrl:athlete,
       athletePowerunit:'111',
       athleteLogoUrl:athleteLogo,
       athleteName:'Rakuten',
       athleteLevel:'LVL 1'
    },
]