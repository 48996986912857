import React from 'react'
import { Grid, GridItem, Text, Flex, Box, Avatar } from '@chakra-ui/react'
import CoverImg from 'assets/images/cardImg.png'
// import { ArrowForwardIcon } from '@chakra-ui/icons' 'https://bit.ly/dan-abramov'
export default function CollectionCard() {
  return (
    <Grid
      mt="11px"
      templateColumns={{
        base: 'repeat(1,1fr)',
        sm: 'repeat(2,1fr)',
        md: 'repeat(3, 1fr)',
      }}
      gap={6}
    >
      {[0, 1, 2, 3, 4, 5].map(item => {
        return (
          <GridItem
            display="flex"
            justifyContent="space-between"
            color="white"
            p="15px"
            key={item}
            w="100%"
            rounded="6px"
            bg="rgb(31,36,54)"
            opacity={1}
            className="gradient-card-border"
          >
            <Flex>
              <Avatar
                src={CoverImg}
                size="lg"
                name="Christian Nwamba"
                className="gradient-border-mask"
              />
              <Box ml="10px" pt="5px">
                <Text className="fs--16 fw--500" color="white">
                  Vilgo Shot
                </Text>
                <Text mt="7px" className="fs--14 fw--500" color="white">
                  1.9k{' '}
                  <Text className="fs--10" color="#99ACD2" as="span">
                    SAR
                  </Text>
                </Text>
              </Box>
            </Flex>
            <Flex
              className="fs--14"
              justify="space-between"
              direction="column"
              textAlign="end"
            >
              <Text color="success">+336</Text>
              <Text color="white">
                2.36k{' '}
                <Text as="span" className="fs--10" color="#99ACD2" ml="7px">
                  SAR
                </Text>
              </Text>
            </Flex>
          </GridItem>
        )
      })}
    </Grid>
  )
}
