import React from 'react'
import {
  Box,
  Container,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuItem,
  IconButton,
  Avatar,
} from '@chakra-ui/react'
import Header from 'components/header'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  CollectionActivity,
  CollectionItems,
  CollectionOffer,
  Twitter,
  Facebook,
  Chat,
} from 'assets/icons'
import SliderCollectionCard from 'components/sliderCard'
import { useNavigate, useParams } from 'react-router-dom'
import Pagination from 'components/pagination'
import { Circle } from 'assets/icons'
import coverBgImage from 'assets/images/myAccBgImg.png'
import { useGetCollectionWithID } from 'utils/apis/collections.api'
export default function SingleCollection() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: singleCollectionRes } = useGetCollectionWithID(id)
  return (
    <Box
      bgImage={coverBgImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <Container maxW="1280px" px="1rem" pb="0.7rem">
        <Header />
        <Box
          display={{ base: 'block', md: 'flex' }}
          gap="22px"
          alignItems="center"
        >
          <Avatar
            display="block"
            mx="auto"
            boxSize={'220px'}
            src={singleCollectionRes?.logoImageUrl}
          />
          <Box>
            <Text
              color="white"
              mt="35px"
              mb="10px"
              className="fs--22 fw--600"
              display={{ base: 'none', md: 'block' }}
            >
              {singleCollectionRes?.collectionName}
            </Text>
            <Box
              display="flex"
              flexDir={{ base: 'column', md: 'row' }}
              gap="22px"
            >
              <Text
                color="white"
                mt="35px"
                mb="10px"
                className="fs--22 fw--600"
                display={{ base: 'block', md: 'none' }}
              >
                {singleCollectionRes?.collectionName}
              </Text>
              <Box flex={0.7} order={{ base: 2, md: 1 }}>
                <Box display={{ sm: 'block', md: 'flex' }} gap="10px">
                  <Flex
                    flex={0.5}
                    mb="11px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px 20px"
                    rounded="7px"
                    bg="primary.boxBg"
                  >
                    <Text className="fs--12 fw--500" color="text">
                      FlOOR
                    </Text>
                    <Text className="fs--12 fw--500" color="white">
                      {singleCollectionRes?.floorPrice}
                    </Text>
                  </Flex>
                  <Flex
                    flex={0.5}
                    mb="11px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px 20px"
                    rounded="7px"
                    bg="primary.boxBg"
                  >
                    <Text className="fs--12 fw--500" color="text">
                      24H VOLUME
                    </Text>
                    <Text className="fs--12 fw--500" color="white">
                      {singleCollectionRes?.saleVolume}K SAR
                    </Text>
                  </Flex>
                </Box>
                <Box display={{ sm: 'block', md: 'flex' }} gap="10px">
                  <Flex
                    flex={0.5}
                    mb="11px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px 20px"
                    rounded="7px"
                    bg="primary.boxBg"
                  >
                    <Text className="fs--12 fw--500" color="text">
                      OWNERS
                    </Text>
                    <Text className="fs--12 fw--500" color="white">
                      {singleCollectionRes?.totalOwners}
                    </Text>
                  </Flex>
                  <Flex
                    flex={0.5}
                    mb="11px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px 20px"
                    rounded="7px"
                    bg="primary.boxBg"
                  >
                    <Text className="fs--12 fw--500" color="text">
                      LISTED
                    </Text>
                    <Text className="fs--12 fw--500" color="white">
                      {singleCollectionRes?.totallisted}
                    </Text>
                  </Flex>
                </Box>
                <Box display={{ sm: 'block', md: 'flex' }} gap="10px">
                  <Flex
                    flex={0.5}
                    mb="11px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px 20px"
                    rounded="7px"
                    bg="primary.boxBg"
                  >
                    <Text className="fs--12 fw--500" color="text">
                      AVG. SALE
                    </Text>
                    <Text className="fs--12 fw--500" color="white">
                      {singleCollectionRes?.avgSale}
                    </Text>
                  </Flex>
                  <Flex
                    flex={0.5}
                    mb="11px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px 20px"
                    rounded="7px"
                    bg="primary.boxBg"
                  >
                    <Text className="fs--12 fw--500" color="text">
                      TOTAL SUPPLY
                    </Text>
                    <Text className="fs--12 fw--500" color="white">
                      {singleCollectionRes?.totalSupply}K
                    </Text>
                  </Flex>
                </Box>
              </Box>

              <Box flex={0.3} order={{ base: 1, md: 2 }}>
                <Flex mb="10px" gap="10px">
                  <IconButton
                    aria-label="fs"
                    border="1px solid  #2F3852"
                    // boxSize="23px"
                    size="sm"
                    bg="primary.boxBg"
                    icon={<Chat />}
                  />

                  <IconButton
                    aria-label="fs"
                    border="1px solid  #2F3852"
                    // boxSize="23px"
                    size="sm"
                    bg="primary.boxBg"
                    icon={<Facebook />}
                  />

                  <IconButton
                    aria-label="fs"
                    border="1px solid  #2F3852"
                    // boxSize="23px"
                    size="sm"
                    bg="primary.boxBg"
                    icon={<Twitter />}
                  />
                </Flex>
                <Text className="fs--14" color="text">
                  Discover the magic of web3. NFT FAN TOKENS wants to help the
                  world embrace web3 by providing everyone with simple and
                  intuitive ways to experience crypto-native tools.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Flex
          align="center"
          justifyContent="space-between"
          mt="68px"
          py="15px"
          pr="20px"
          borderTop="1px solid #2F3852"
          borderBottom="1px solid #2F3852"
        >
          <Box>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                bg="transparent"
                _hover={{}}
                rightIcon={<ChevronDownIcon />}
              >
                Recently viewed
              </MenuButton>
              <MenuList
                minWidth="170px"
                bg="primary.100"
                border="1px solid #2F3852"
                p="15px"
              >
                <MenuItem
                  className="fs--12"
                  mb="20px"
                  p={0}
                  icon={<Circle />}
                  bg="transparent"
                >
                  <Text color="white">
                    Price : <Text as="span">Low to high</Text>
                  </Text>
                </MenuItem>
                <MenuItem
                  className="fs--12"
                  p={0}
                  icon={<Circle />}
                  bg="transparent"
                >
                  <Text color="white">
                    Price : <Text as="span">High to Low</Text>
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
          <Flex gap={{ base: '15px', md: '50px' }}>
            <Flex>
              <CollectionItems />
              <Text ml="10px" display={{ base: 'none', md: 'block' }}>
                Items
              </Text>
            </Flex>
            <Flex>
              <CollectionOffer />
              <Text ml="10px" display={{ base: 'none', md: 'block' }}>
                Collection Offers
              </Text>
            </Flex>
            <Flex>
              <CollectionActivity />
              <Text ml="10px" display={{ base: 'none', md: 'block' }}>
                Activity
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          wrap="wrap"
          justify="center"
          mb="20px"
          gap={{ base: '10px', sm: '20px', md: '30px' }}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7].map(item => {
            return (
              <Box position="relative" zIndex={1} key={item}>
                <SliderCollectionCard onclick={() => navigate('/view-NFT')} />
              </Box>
            )
          })}
        </Flex>

        <Flex justifyContent={{ base: 'center', md: 'end' }}>
          <Pagination />
        </Flex>
      </Container>
    </Box>
  )
}
