import { Button } from './components/button'
import { Text } from './components/text'
import { Input } from './components/input'
export default {
  fonts: {
    body: 'Gotham',
    heading: 'Gotham',
  },
  colors: {
    primary: {
      boxBg: '#232C47',
      modalBg: '#1A1B21',
      100: '#232C47', //search box color
      400: '#4F81FD',
      btnBg:
        'transparent linear-gradient(94deg, #C03DFD 0%, #4F81FD 100%) 0% 0% no-repeat padding-box',
    },
    secondary: { 500: 'linear-gradient(90deg, #64647A, #5B4582)' },
    text: '#99ACD2',
    cardbg: '#1E1F27',
    success: '#0DFE8D',
    inputbg: '#232C47',
    border: '#2F3852',
    tableBg: '#0E101A',
  },
  styles: {
    global: {
      body: {
        bg: '#131623',
      },
    },
  },
  components: {
    Button,
    Text,
    Input,
  },
}
