interface collection {
  imgUrl: string
  collectionName: string
}
export interface ClubCollection {
  collection: collection
  dayVolume: number
  floorPrice: number
  collectionImages: string[]
}
export const collectionData: ClubCollection[] = [
  {
    collection: {
      imgUrl: 'https://bit.ly/dan-abramov',
      collectionName: 'Vilgo Shot',
    },
    dayVolume: 19,
    floorPrice: 6235,
    collectionImages: [
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
    ],
  },
  {
    collection: {
      imgUrl: 'https://bit.ly/dan-abramov',
      collectionName: 'Vilgo Shot',
    },
    dayVolume: 397,
    floorPrice: 6235,
    collectionImages: [
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
    ],
  },
  {
    collection: {
      imgUrl: 'https://bit.ly/dan-abramov',
      collectionName: 'Vilgo Shot',
    },
    dayVolume: 25,
    floorPrice: 6235,
    collectionImages: [
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
    ],
  },
  {
    collection: {
      imgUrl: 'https://bit.ly/dan-abramov',
      collectionName: 'Vilgo Shot',
    },
    dayVolume: 18,
    floorPrice: 3265,
    collectionImages: [
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
      'https://bit.ly/dan-abramov',
    ],
  },
]
