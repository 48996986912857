import React from 'react'
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
  useMultiStyleConfig,
  useTab,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import Header from 'components/header'
import SliderCollectionCard from 'components/sliderCard'
import { Matic, TopNFT } from 'assets/icons'
import { TbCopy } from 'react-icons/tb'
import { AllInvoices } from 'utils/types/collections.type'
import { createColumnHelper } from '@tanstack/react-table'
import { allInvoices } from 'utils/data/collections.data'
import {
  AiOutlinePrinter,
  AiOutlineDownload,
  AiOutlineEye,
} from 'react-icons/ai'
import DataTable from 'components/table'
import PostLayout from 'components/postLayout'
import coverImage from 'assets/images/newTrendindCollection1.png'
import Pagination from 'components/pagination'
import FolderUpload from 'assets/images/folder-upload.png'
import coverBgImage from 'assets/images/myAccBgImg.png'

export default function MyAccount() {
  const CustomTab = React.forwardRef<HTMLButtonElement, ButtonProps>(
    function CustomTab(props, ref) {
      // 1. Reuse the `useTab` hook
      const tabProps = useTab({ ...props, ref })
      const isSelected = !!tabProps['aria-selected']

      // 2. Hook into the Tabs `size`, `variant`, props
      const styles = useMultiStyleConfig('Tabs', tabProps)

      return (
        <Button
          __css={styles.tab}
          {...tabProps}
          border="0"
          fontSize={{ base: '14px', md: '16px' }}
          color={isSelected ? 'white' : 'text'}
          mr={{ base: '15px', md: '75px' }}
          px="0"
          _active={{}}
          className={isSelected ? 'gradient-mask' : ''}
        >
          {tabProps.children}
        </Button>
      )
    },
  )
  return (
    <Box
      bgImage={coverBgImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <Container maxW="1280px" px="1rem">
        <Header />
        <Tabs>
          <TabList border="0">
            {['Profile', 'Social media', 'Resale NFT', 'Invoices'].map(item => (
              <CustomTab key={item}>{item}</CustomTab>
            ))}
          </TabList>

          <TabPanels>
            <TabPanel p="0" h="100vh">
              <Profile />
            </TabPanel>
            <TabPanel p="0">
              <SocialMedia />
            </TabPanel>
            <TabPanel p="0">
              <ResaleNFT />
            </TabPanel>
            <TabPanel p="0" h="100vh">
              <Invoices />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  )
}

function Profile() {
  const {
    isOpen: profileIsOpen,
    onOpen: profileOnOpen,
    onClose: profilOnclose,
  } = useDisclosure()
  const [type, setType] = React.useState<string>('')
  return (
    <>
      <Heading className="fs--18" color="white" mt="60px">
        Personal info
      </Heading>
      <Text mb="18px">Update your photo and personal details here.</Text>
      <Box rounded="7px" border="1px" borderColor="border" p="25px">
        <Text mb="18px">Total Balance</Text>
        <HStack mb="15px">
          <Center bg="pink" p="7px" borderRadius="50%">
            <Matic />
          </Center>
          <Heading className="fs--30" color="white">
            158999.23
            <Text as="span" ml="5px" className="fs--14">
              MATIC
            </Text>
          </Heading>
        </HStack>
        <HStack
          p="10px 25px"
          border="1px"
          borderColor="border"
          rounded="7px"
          bg="#181E30"
        >
          <Text flex={0.235} color="white">
            Public Address
          </Text>
          <Text flex={0.75}>0xcr.....efdrs</Text>
          <IconButton
            size="sm"
            aria-label="copy"
            icon={<TbCopy color="black" />}
          />
        </HStack>
        <Box
          mt="18px"
          p="10px 25px"
          border="1px"
          borderColor="border"
          rounded="7px"
          bg="#181E30"
        >
          <HStack>
            <Text className="white" flex={0.25}>
              Name
            </Text>
            <Text flex={0.75}>Alexa Kavin</Text>
            <Button
              variant="ghost"
              color="blue.300"
              flex={0.05}
              onClick={() => {
                setType('Name')
                profileOnOpen()
              }}
            >
              Change
            </Button>
          </HStack>
          <HStack my="18px">
            <Text className="white" flex={0.225}>
              Email
            </Text>
            <Text flex={0.75}>AlexaKavin@gmail.com</Text>
          </HStack>
          <HStack>
            <Text className="white" flex={0.25}>
              Password
            </Text>
            <Text flex={0.75}>*************</Text>
            <Button
              variant="ghost"
              color="blue.300"
              flex={0.05}
              onClick={() => {
                setType('Password')
                profileOnOpen()
              }}
            >
              Change
            </Button>
          </HStack>
        </Box>
      </Box>
      <ChangeNameOrPassword
        isOpen={profileIsOpen}
        onClose={profilOnclose}
        type={type}
      />
    </>
  )
}
function Invoices() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLargerThan768] = useMediaQuery('(min-width: 769px)')

  return (
    <>
      <HStack justify="space-between" mt="60px">
        <Heading color="white" className="fs--18">
          All Invoices
        </Heading>
        <Button onClick={onOpen}>Complete KYC</Button>
      </HStack>
      <Box
        mt="18px"
        border="1px"
        borderColor="border"
        rounded="7px"
        bg="#181E30"
        p="10px 7px"
      >
        <DataTable
          columns={isLargerThan768 ? columns : mobileViewCols}
          data={allInvoices}
        />
        <Flex justifyContent={{ base: 'center', md: 'end' }}>
          <Pagination />
        </Flex>
      </Box>
      <KYCModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
const columnHelper = createColumnHelper<AllInvoices>()

const columns = [
  columnHelper.accessor('NFTID', {
    cell: info => (
      <HStack alignItems="center" gap="15px" className="fs--14">
        <Image
          boxSize="48px"
          src={info?.row?.original?.image}
          className="gradient-border-mask"
        />
        <Text color="text">{info?.row?.original?.NFTID}</Text>
      </HStack>
    ),
    header: 'NFT ID',
  }),
  columnHelper.accessor('invoiceID', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info.getValue()}
      </Text>
    ),
    header: 'Invoice ID',
  }),
  columnHelper.accessor('date', {
    cell: info => (
      <Text
        color="text"
        className="fs--14"
        display={{ base: 'none', sm: 'block' }}
      >
        {info?.getValue()}
      </Text>
    ),
    header: 'Invoice Date',
  }),
  columnHelper.accessor('amount', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()}{' '}
        <Text as="span" className="fs--14">
          SAR
        </Text>
      </Text>
    ),

    header: 'Invoice Amount',
  }),

  columnHelper.accessor('amount', {
    cell: () => (
      <HStack>
        <svg width="0" height="0">
          <linearGradient
            id="blue-gradient"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="0%"
          >
            <stop stopColor="#7a6ded" offset="0%" />
            <stop stopColor="#591885" offset="100%" />
          </linearGradient>
        </svg>
        <IconButton
          size="sm"
          aria-label="Search database"
          bg="#FFFFFF1A"
          icon={
            <AiOutlineEye
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
        />
        <IconButton
          size="sm"
          aria-label="Search database"
          bg="#FFFFFF1A"
          icon={
            <AiOutlineDownload
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
        />{' '}
        <IconButton
          size="sm"
          bg="#FFFFFF1A"
          aria-label="Search database"
          icon={
            <AiOutlinePrinter
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
        />{' '}
      </HStack>
    ),
    header: 'Actions',
  }),
]
const mobileViewCols = [
  columnHelper.accessor('NFTID', {
    cell: info => (
      <HStack alignItems="center" gap="15px" className="fs--14">
        <Image
          boxSize="48px"
          src={info?.row?.original?.image}
          className="gradient-border-mask"
        />
        <Text color="text" className="fs--12">
          {info?.row?.original?.NFTID}
        </Text>
      </HStack>
    ),
    header: 'NFT ID',
  }),
  columnHelper.accessor('invoiceID', {
    cell: info => (
      <Text color="text" className="fs--12">
        {info.getValue()}
      </Text>
    ),
    header: 'Invoice ID',
  }),
]

function SocialMedia() {
  return (
    <>
      <Heading className="fs--18" color="white" mt="60px">
        User Posts
      </Heading>
      <Text>User posts will be appears here.</Text>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        mt="21px"
        gap={{ base: '0', md: '24px' }}
        align="start"
        justify="center"
      >
        <Box>
          <PostLayout
            image={coverImage}
            comment="Tabs expects TabList and TabPanels as children. The order
            doesn't matter, you can have TabList at the top, at the bottom,
            or both."
          />
          <Box mt="12px" mb="2rem">
            <PostLayout plainText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.into electronic typesetting, remaining essentially unchanged." />
          </Box>{' '}
        </Box>
        <Box display={{ base: 'none', lg: 'block' }}>
          <Box
            p="25px"
            border="1px"
            borderColor="border"
            rounded="7px"
            bg="#181E30"
            w={{ base: '100%', md: '438px' }}
          >
            <HStack>
              <TopNFT />
              <Heading className="fs--18" color="white">
                Trending NFT&apos;s
              </Heading>
            </HStack>
            <Wrap mt="20px">
              {[1, 2, 3, 4, 5, 6].map(item => (
                <WrapItem key={item}>
                  <Image
                    rounded="7px"
                    boxSize="123px"
                    src="https://bit.ly/code-beast"
                    alt="nft"
                  />
                </WrapItem>
              ))}
            </Wrap>
            <Center color="text" mt="8px" className="fs--12 fw--700">
              view all
            </Center>
          </Box>
          <Box
            mt="12px"
            p="25px"
            border="1px"
            borderColor="border"
            rounded="7px"
            bg="#181E30"
            w="100%"
            maxW="438px"
          >
            <HStack>
              <TopNFT />
              <Heading className="fs--18" color="white">
                Top Collections
              </Heading>
            </HStack>
            {[1, 2].map(item => (
              <Box
                key={item}
                bgImage={coverImage}
                bgSize="cover"
                bgPos="center"
                rounded="7px"
                h="132px"
                my="9px"
                className="NFT-image"
              >
                <VStack mx="17px" justify="end" h="inherit">
                  <HStack w="100%" justify="space-between" mb="10px" zIndex={2}>
                    <HStack>
                      {' '}
                      <Avatar src="https://bit.ly/code-beast" />
                      <Text className="fs--12" color="white">
                        NFT FAN TOKENS
                      </Text>
                    </HStack>
                    <Text ml="100%" className="fs--10" color="white">
                      345,232{' '}
                      <Text as="span" fontSize="8px" color="white">
                        SAR
                      </Text>
                    </Text>
                  </HStack>
                </VStack>
              </Box>
            ))}
            <Center color="text" mt="8px" className="fs--12 fw--700">
              view all
            </Center>
          </Box>
        </Box>
      </Flex>
    </>
  )
}
function ResaleNFT() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Heading color="white" mt="2rem" mb="1rem">
        Resale NFT&apos;s
      </Heading>
      <Flex wrap="wrap" gap={{ base: '10px', sm: '20px', md: '25px' }}>
        {[1, 2, 9, 9, 9, 3, 5].map(card => (
          <Box position="relative" zIndex={1} key={card}>
            <SliderCollectionCard onclick={onOpen} btnType={'Resale NFT'} />
          </Box>
        ))}
      </Flex>
      <ResaleNFTModal isOpen={isOpen} onClose={onClose} />
      <Flex
        justifyContent={{ base: 'center', md: 'end' }}
        mt="0.6rem"
        pb="2rem"
      >
        <Pagination />
      </Flex>
    </>
  )
}

interface IResaleModal {
  isOpen: boolean
  onClose: () => void
}
function ResaleNFTModal({ isOpen, onClose }: IResaleModal) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent" className="glass-effect-2">
        <ModalHeader color="white">Resale NFT</ModalHeader>
        <ModalBody px="42px">
          <Input
            placeholder="Enter price"
            border="2px"
            variant="input"
            rounded="7px"
          />
          <HStack justify="space-between" my="15px">
            <Text>Total in MATIC</Text>
            <Text color="white">36.34 MATIC</Text>
          </HStack>
          <HStack justify="space-between">
            <Text>Platform Fee</Text>
            <Text color="white">3.434 SAR</Text>
          </HStack>
        </ModalBody>

        <ModalFooter display="block" p="25px 42px">
          <Button w="100%" mb="12px">
            Resale{' '}
          </Button>
          <Button variant="outlineltr" w="100%" onClick={onClose}>
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function ChangeNameOrPassword({
  isOpen,
  onClose,
  type,
}: {
  isOpen: boolean
  onClose: () => void
  type: string
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="primary.modalBg"
        borderColor="border"
        border="1px"
        padding="2rem 2rem"
        m="0.6rem"
      >
        <ModalHeader color="white" py="0">
          Change {type}
        </ModalHeader>
        <ModalBody>
          {type === 'Password' ? (
            <>
              {' '}
              <Input
                placeholder="Current Password"
                border="2px"
                variant="input"
                mt="16px"
              />
              <Input
                placeholder="New Password"
                border="2px"
                variant="input"
                my="16px"
              />
              <Input
                placeholder="Confirm Password"
                border="2px"
                variant="input"
              />
            </>
          ) : (
            <>
              <Input placeholder="First Name" border="2px" variant="input" />
              <Input
                placeholder="Last Name"
                border="2px"
                variant="input"
                mt="16px"
              />
            </>
          )}
        </ModalBody>

        <ModalFooter display="block">
          <Button w="100%" colorScheme="blue" mr={3} onClick={onClose}>
            Save Changes
          </Button>
          <Button w="100%" variant="outlineltr" mt="16px">
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
function KYCModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="primary.modalBg"
        borderColor="border"
        border="1px"
        padding="1rem"
        m="0.5rem"
      >
        <ModalBody>
          <Text color="white" my="1rem">
            Kindly provide your bank account IBAN number where you want to
            receive the payments. Thanks!
          </Text>
          <HStack align="stretch">
            <Box flex={0.5}>
              <Input placeholder="Account holder" variant="input" />
              <Input placeholder="Bank name" variant="input" my="16px" />

              <Input placeholder="IBAN" variant="input" />
            </Box>
            <Box
              flex={0.5}
              p="0.6rem"
              textAlign="center"
              bg="#0E101A"
              border="1px"
              borderRadius="4px"
              borderColor="#2F3852"
            >
              <Image src={FolderUpload} alt="img" mt="2rem" />
              <Text>Upload Bank Statement</Text>
            </Box>
          </HStack>
        </ModalBody>

        <ModalFooter display="block">
          <Button w="100%" colorScheme="blue" mr={3} onClick={onClose}>
            Save{' '}
          </Button>
          <Button w="100%" variant="outlineltr" mt="16px">
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
