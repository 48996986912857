import React from 'react'
import { Text, Container, Grid, GridItem, Image, Flex } from '@chakra-ui/react'
import mainLogoWhite from 'assets/icons/login/logoWhite.svg'
import FooterImg from 'assets/images/FooterImage.png'
export default function Footer() {
  const marketPlace = [
    'All NFT',
    'Collections',
    'All Athletes',
    'All clubs',
    'Trading Cards',
    'Sports',
  ]
  const myAccount = [
    'Profile',
    'Favorites',
    'WatchList',
    'My Collections',
    'Create',
    'Settings',
  ]
  const resources = [
    'Learn',
    'Help Center',
    'Platform Status',
    'Partners',
    'Taxes',
    'Newsletter',
  ]
  const company = ['About', 'Career', 'Ventures', 'Grants']
  return (
    <Flex
      backgroundImage={`url('${FooterImg}')`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      height="85vh"
      backgroundSize="cover"
      alignItems="center"
    >
      <Container maxW="1280px">
        <Grid
          mt="80px"
          templateRows={{ base: 'repeat(2,1fr)', md: 'repeat(1,1fr)' }}
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem colSpan={{ base: 12, md: 4 }} mt={{ base: '25%', md: '0' }}>
            <Image
              mt="7px"
              mb={{ base: '0px', md: '48px' }}
              h="50px"
              src={mainLogoWhite}
            />
            <Text as="p" className="fs--14 fw--400" w="85%" color="white">
              The world&apos;s first and largest digital marketplace for crypto
              collectibles and non-fungible tokens (NFTs). Buy, sell, and
              discover exclusive digital items.
            </Text>
          </GridItem>
          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display="flex"
            flexDirection="column"
            justifyContent="start"
          >
            <Text mb="20px" color="white" className="fs--16 fw--700" as="span">
              Marketplace
            </Text>
            {marketPlace.map(link => (
              <Text mb="20px" key={link} color="white">
                {link}
              </Text>
            ))}
          </GridItem>
          <GridItem
            colSpan={2}
            display={{ base: 'none', md: 'flex' }}
            flexDirection="column"
          >
            <Text mb="20px" className="fs--16 fw--700" color="white">
              My Account
            </Text>
            {myAccount.map(item => (
              <Text mb="20px" color="white" key={item}>
                {item}
              </Text>
            ))}
          </GridItem>
          <GridItem
            colSpan={2}
            display={{ base: 'none', md: 'flex' }}
            flexDirection="column"
          >
            <Text mb="20px" className="fs--16 fw--700" color="white">
              Resources
            </Text>
            {resources.map(item => (
              <Text mb="20px" color="white" key={item}>
                {item}
              </Text>
            ))}
          </GridItem>
          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display="flex"
            flexDirection="column"
          >
            <Text mb="20px" className="fs--16 fw--700" color="white">
              Company
            </Text>
            {company.map(item => (
              <Text mb="20px" color="white" key={item}>
                {item}
              </Text>
            ))}
          </GridItem>

          {/*  */}
        </Grid>
        <Flex
          alignItems="center"
          justify="space-between"
          py="1rem"
          borderTop=" 1px solid #FFFFFF4D"
        >
          <Text color="white">
            ©2018 - {new Date().getFullYear()} Fan Sphere, Inc
          </Text>
          <Flex>
            <Text mr="62px" color="white">
              Privacy Policy
            </Text>
            <Text color="white">Terms of Services</Text>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}
