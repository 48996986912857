import { defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    borderRadius: 'md',
    color: 'white',
    fontSize: '14px',
    fontWeight: 400,
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and primary
  variants: {
    primary: {
      fontSize: '14px',
      fontWeight: 400,
      bg: 'transparent linear-gradient(94deg, #C03DFD 0%, #4F81FD 100%) 0% 0% no-repeat padding-box',
    },
    outline: {
      position: 'relative',
      border: 'none',
      color: 'white',
      fontSize: '14px',
      fontWeight: 400,
      '::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '8px',

        border: '2px solid transparent',
        background: 'linear-gradient(#4F81FD,#C03DFD) border-box',
        'mask-composite': 'exclude',
        '-webkit-mask': `linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0)`,
        '-webkit-mask-composite': 'xor',
        borderColor: 'transparent',
      },
    },
    outlineltr: {
      position: 'relative',
      border: 'none',
      color: 'white',
      fontSize: '14px',
      fontWeight: 400,
      '::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '8px',

        border: '2px solid transparent',
        background: 'linear-gradient(90deg,#C03DFD,#4F81FD) border-box',
        'mask-composite': 'exclude',
        '-webkit-mask': `linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0)`,
        '-webkit-mask-composite': 'xor',
        borderColor: 'transparent',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
})
