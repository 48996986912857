import React from 'react'
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { PostGifIcon, PostImageIcon, PostVideoIcon, TopNFT } from 'assets/icons'
import coverImage from 'assets/images/newTrendindCollection1.png'
import profileAvatar from 'assets/images/cardImg.png'
import PostLayout from 'components/postLayout'
export default function ClubSocialMedia() {
  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      mt="21px"
      gap="24px"
      alignItems="start"
      justifyContent="center"
    >
      <Box>
        <Box
          mb="25px"
          border="1px"
          borderColor="border"
          rounded="7px"
          bg="#181E30"
          maxW="725px"
        >
          <HStack align="start" pt="35px" px="25px">
            <Image boxSize="36px" src={profileAvatar} />
            <Textarea
              placeholder="Here is a sample placeholder"
              className="fs--14"
              variant="unstyled"
            />
            <Button rightIcon={<ChevronRightIcon />}>Public</Button>
          </HStack>

          <HStack bg="#20273C" py="15px" mt="1.3rem">
            <Button
              flex={1}
              variant="ghost"
              leftIcon={<PostImageIcon />}
              color="white"
              _active={{}}
              className="fs--12"
            >
              Photo
            </Button>
            <Button
              flex={1}
              variant="ghost"
              leftIcon={<PostVideoIcon />}
              color="white"
              _active={{}}
              className="fs--12"
              py="0"
              borderLeft="2px solid #99ACD2"
              borderRight="2px solid #99ACD2"
              borderRadius="0"
            >
              Video
            </Button>
            <Button
              flex={1}
              variant="ghost"
              leftIcon={<PostGifIcon />}
              color="white"
              _active={{}}
              className="fs--12"
            >
              Gif
            </Button>
          </HStack>
        </Box>
        <PostLayout
          image={coverImage}
          comment="Tabs expects TabList and TabPanels as children. The order
        doesn't matter, you can have TabList at the top, at the bottom,
        or both."
        />
        <Box mt="12px" mb="2rem">
          <PostLayout plainText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.into electronic typesetting, remaining essentially unchanged." />
        </Box>{' '}
      </Box>
      <Box display={{ base: 'none', lg: 'block' }}>
        <Box
          p="25px"
          border="1px"
          borderColor="border"
          rounded="7px"
          bg="#181E30"
          w={{ base: '100%', md: '438px' }}
        >
          <HStack>
            <TopNFT />
            <Heading className="fs--18" color="white">
              Trending NFT&apos;s
            </Heading>
          </HStack>
          <Wrap mt="20px">
            {[1, 2, 3, 4, 5, 6].map(item => (
              <WrapItem key={item}>
                <Image
                  rounded="7px"
                  boxSize="123px"
                  src="https://bit.ly/code-beast"
                  alt="nft"
                />
              </WrapItem>
            ))}
          </Wrap>
          <Center color="text" mt="8px" className="fs--12 fw--700">
            view all
          </Center>
        </Box>
        <Box
          mt="12px"
          p="25px"
          border="1px"
          borderColor="border"
          rounded="7px"
          bg="#181E30"
          w={{ base: '100%', md: '438px' }}
        >
          <HStack>
            <TopNFT />
            <Heading className="fs--18" color="white">
              Top Collections
            </Heading>
          </HStack>
          {[1, 2].map(item => (
            <Box
              key={item}
              bgImage={coverImage}
              bgSize="cover"
              bgPos="center"
              rounded="7px"
              h="132px"
              my="9px"
              className="NFT-image"
            >
              <VStack mx="17px" justify="end" h="inherit">
                <HStack w="100%" justify="space-between" mb="10px" zIndex={2}>
                  <HStack>
                    {' '}
                    <Avatar src="https://bit.ly/code-beast" />
                    <Text className="fs--12" color="white">
                      NFT FAN TOKENS
                    </Text>
                  </HStack>
                  <Text ml="100%" className="fs--10">
                    345,232{' '}
                    <Text as="span" fontSize="8px">
                      SAR
                    </Text>
                  </Text>
                </HStack>
              </VStack>
            </Box>
          ))}
          <Center color="text" mt="8px" className="fs--12 fw--700">
            view all
          </Center>
        </Box>
      </Box>
    </Flex>
  )
}
