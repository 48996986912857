import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import coverImage from 'assets/images/mainbgImg.png'
import mainLogo from 'assets/icons/login/logo.svg'
import { HiOutlineEnvelope } from 'react-icons/hi2'
import { AiFillLock } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ILogin, ISignUp } from 'utils/types/auth.type'
import { SubmitHandler } from 'react-hook-form/dist/types'
import {
  useChangePassword,
  useForgetPassword,
  useLogin,
  useSignUp,
  useVerifyOTP,
} from 'utils/apis/auth.api'
export default function Login() {
  const [login, setLogin] = React.useState('login')
  const navigate = useNavigate()
  const { mutate, isSuccess, data: loginRes } = useLogin()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUp>()

  const onSubmit: SubmitHandler<ILogin> = data => {
    console.log(data)
    mutate(data)
  }
  useEffect(() => {
    if (isSuccess) {
      console.log('iddd', loginRes)

      localStorage.setItem('access_token', loginRes?.token)
      // localStorage.setItem('userID', loginRes?.id)
      navigate('/')
    }
  }, [isSuccess])
  return (
    <Box bgImage={coverImage} bgSize="cover" bgRepeat="no-repeat" h="100vh">
      <Container maxW="1280px" px="1rem">
        <Image src={mainLogo} my="27px" />
        {login === 'login' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack h="70vh" gap="16px" justify="center" maxW="410px" mx="auto">
              <Heading className="fs--35 fw--600" color="white">
                Login
              </Heading>
              <Box w="100%">
                <InputGroup>
                  <InputRightElement>
                    <HiOutlineEnvelope color="white" />
                  </InputRightElement>

                  <Input
                    placeholder="Email"
                    variant="input"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        // This regular expression matches the standard email format.
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                  />
                </InputGroup>
                {errors.email && (
                  <Text color="red.500" className="fs--10">
                    {errors.email?.message}
                  </Text>
                )}
              </Box>
              <Box w="100%">
                <InputGroup>
                  <InputRightElement>
                    <AiFillLock color="white" />
                  </InputRightElement>
                  <Input
                    placeholder="Password"
                    type="password"
                    variant="input"
                    {...register('password', {
                      required: 'password is required',
                    })}
                  />
                </InputGroup>
                {errors.password && (
                  <Text color="red.500" className="fs--10">
                    {errors.password?.message}
                  </Text>
                )}
              </Box>
              <Link
                color="white"
                onClick={() => setLogin('forgetPassword')}
                textDecoration="underline"
              >
                Forget your password
              </Link>
              <Button w="100%" mt="1.5rem" type="submit">
                Login
              </Button>
              <Link
                color="white"
                textDecoration="underline"
                onClick={() => setLogin('createAccount')}
              >
                Create account
              </Link>
              <Link color="white" href="/" textDecoration="underline">
                Return to Home
              </Link>
            </VStack>
          </form>
        )}
        {login === 'createAccount' && (
          <>
            <CreateAccount setLogin={setLogin} />
          </>
        )}
        {login === 'forgetPassword' && (
          <>
            <ForgetPassword setLogin={setLogin} />
          </>
        )}
      </Container>
    </Box>
  )
}

function CreateAccount({
  setLogin,
}: {
  setLogin: React.Dispatch<React.SetStateAction<string>>
}) {
  const { mutate, isSuccess, data: response } = useSignUp()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ISignUp>()
  const password = React.useRef({})
  password.current = watch('password', '')
  const onSubmit: SubmitHandler<ISignUp> = data => {
    const { confirmPass, ...newData } = data
    console.log(confirmPass)
    mutate(newData)
  }
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('access_token', response?.token)
      setLogin('login')
    }
  }, [isSuccess])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack h="70vh" gap="1rem" justify="center" maxW="410px" mx="auto">
        <Heading className="fs--35 fw--600" color="white">
          Create Account
        </Heading>
        <Box w="100%">
          <Input
            placeholder="Ful Name"
            variant="input"
            {...register('name', { required: true })}
          />
          {errors.name && (
            <Text color="red.500" className="fs--10">
              Last name is required
            </Text>
          )}
        </Box>
        <Box w="100%">
          <Input
            placeholder="Email"
            variant="input"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                // This regular expression matches the standard email format.
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <Text color="red.500" className="fs--10">
              {errors.email?.message}
            </Text>
          )}
        </Box>
        <Box w="100%">
          <Input
            placeholder="Password"
            type="password"
            variant="input"
            {...register('password', {
              required: 'password is required',
              minLength: 8,
            })}
          />
          {errors.password && (
            <Text color="red.500" className="fs--10">
              {errors.password?.message}
            </Text>
          )}
        </Box>
        <Box w="100%">
          <Input
            placeholder="Confirm Password"
            type="password"
            variant="input"
            {...register('confirmPass', {
              required: 'confirm password is required',
              validate: value =>
                value === password?.current || 'Passwords do not match',
            })}
          />
          {errors.confirmPass && (
            <Text color="red.500" className="fs--10">
              {errors.confirmPass?.message}
            </Text>
          )}
        </Box>
        <Button w="100%" mt="1.5rem" type="submit">
          Create Account
        </Button>

        <Link
          color="white"
          mt="1rem"
          onClick={() => setLogin('login')}
          textDecoration="underline"
        >
          Back to Login
        </Link>
        <Link color="white" href="/" textDecoration="underline">
          Return to Home
        </Link>
      </VStack>
    </form>
  )
}
type IForgetPassInputs = {
  email?: string
  otp?: number
  password?: string
  confirmPass?: string
}
function ForgetPassword({
  setLogin,
}: {
  setLogin: React.Dispatch<React.SetStateAction<string>>
}) {
  const [scrtyCode, setScrtyCode] = React.useState('password')
  const { mutate: mutateForgetPassword, isSuccess: isSuccessForgetPassword } =
    useForgetPassword()
  const {
    mutate: mutateVerifyOTP,
    isSuccess: isSuccessVerifyOTP,
    data: otpRes,
  } = useVerifyOTP()
  const { mutate: mutateChangePassword, isSuccess: isSuccessChangePassword } =
    useChangePassword(otpRes?.token || '')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IForgetPassInputs>()
  const password = React.useRef<string | undefined>()
  password.current = watch('password', '')
  const onSubmit: SubmitHandler<IForgetPassInputs> = data => {
    const { email } = data
    const { otp } = data
    const { confirmPass, ...password } = data

    if (email && !otp) {
      mutateForgetPassword({ email, isUser: true })
    }

    if (otp && email && !confirmPass) {
      mutateVerifyOTP({ otp, isUser: true, email })
    }
    if (password?.password && confirmPass) {
      console.log(confirmPass)
      mutateChangePassword(password?.password)
    }
  }

  useEffect(() => {
    if (isSuccessForgetPassword) {
      setScrtyCode('code')
    }
    if (isSuccessVerifyOTP) {
      setScrtyCode('newPassword')
    }
    if (isSuccessChangePassword) {
      setLogin('login')
    }
  }, [isSuccessForgetPassword, isSuccessVerifyOTP, isSuccessChangePassword])
  return (
    <>
      {scrtyCode === 'password' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack h="70vh" gap="16px" justify="center" maxW="410px" mx="auto">
            {' '}
            <Heading className="fs--35 fw--600" color="white">
              Forget Password
            </Heading>
            <Text>Enter your email to reset your password</Text>
            <Box w="100%">
              <InputGroup>
                <InputRightElement>
                  <HiOutlineEnvelope color="white" />
                </InputRightElement>
                <Input
                  placeholder="Email"
                  variant="input"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      // This regular expression matches the standard email format.
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
              </InputGroup>
              {errors.email && (
                <Text color="red.500" className="fs--10">
                  {errors.email?.message}
                </Text>
              )}
            </Box>
            <Button w="100%" mt="1.5rem" type="submit">
              Next
            </Button>
            <Link
              color="white"
              mt="1rem"
              onClick={() => setLogin('login')}
              textDecoration="underline"
            >
              Return to Login
            </Link>
          </VStack>
        </form>
      )}
      {scrtyCode === 'code' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack h="70vh" gap="16px" justify="center" maxW="410px" mx="auto">
            <Heading className="fs--35 fw--600" color="white">
              Enter Security Code
            </Heading>
            <Text textAlign="center">
              We sent a 6-digit code to email@xxx.com. This code will expire
              after 59 second.
            </Text>

            <Input
              placeholder="464645"
              type="number"
              variant="input"
              maxLength={6}
              minLength={6}
              textAlign="center"
              letterSpacing="20px"
              {...register('otp', {
                required: 'opt is required',
                maxLength: 6,
                minLength: 6,
              })}
            />
            {errors.otp && (
              <Text color="red.500" className="fs--10">
                {errors.otp?.message}
              </Text>
            )}
            <HStack w="100%" justify="end">
              <Text>Didn&apos; receive a code? </Text>
              <Text color="white" cursor="pointer">
                Resend
              </Text>
            </HStack>
            <Button w="100%" mt="1.5rem" type="submit">
              Next
            </Button>
          </VStack>
        </form>
      )}
      {scrtyCode === 'newPassword' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack h="70vh" gap="16px" justify="center" maxW="410px" mx="auto">
            <Heading
              className=" fw--600"
              fontSize={{ base: '30px', md: '35px' }}
              mb={{ base: '2rem', md: '62px' }}
              color="white"
            >
              Create new Password
            </Heading>
            <Box w="100%">
              <Input
                placeholder="Password"
                type="password"
                variant="input"
                {...register('password', {
                  required: 'password is required',
                  minLength: 8,
                })}
              />
              {errors.password && (
                <Text color="red.500" className="fs--10">
                  {errors.password?.message}
                </Text>
              )}
            </Box>
            <Box w="100%">
              <Input
                placeholder="Confirm Password"
                type="password"
                variant="input"
                {...register('confirmPass', {
                  required: 'confirm password is required',
                  validate: value =>
                    value === password?.current || 'Passwords do not match',
                })}
              />
              {errors.confirmPass && (
                <Text color="red.500" className="fs--10">
                  {errors.confirmPass?.message}
                </Text>
              )}
            </Box>
            <Button w="100%" mt="1.5rem" type="submit">
              Submit
            </Button>
          </VStack>
        </form>
      )}
    </>
  )
}
