import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { ClubCard } from 'utils/types/tandingPage.Types'
import { useNavigate } from 'react-router-dom'
export function ExploreClubCard({ data }: { data: ClubCard }) {
  const navigate = useNavigate()
  return (
    <Flex
      className="gradient-club-card-border"
      padding="15px"
      width="160px"
      height="241px"
      rounded="20px"
      bg="rgb(33,36,44)"
      position="relative"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      mt="1rem"
      onClick={() => navigate('/specific-club')}
    >
      <Box
        filter="blur(30px)"
        w="80%"
        height="55%"
        mt="15px"
        padding="10px"
        rounded="full"
        background="radial-gradient(#4F81FD,  #C03DFD)"
      ></Box>
      <Image
        height="45%"
        position="absolute"
        top="35%"
        left="50%"
        transform="translate(-50%, -50%)"
        zIndex="100"
        src={data?.clubLogourl}
      />
      <Flex
        p="15px"
        align="center"
        justify="center"
        rounded="10px"
        bg="rgb(37,41,79)"
        cursor="pointer"
      >
        <Text
          className="fs--14 fw--600"
          color="white"
          textAlign="center"
          cursor="pointer"
        >
          {data?.clubName}s
        </Text>
      </Flex>
    </Flex>
  )
}
