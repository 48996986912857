import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const input = definePartsStyle({
  field: {
    border: '1px solid',
    color: '#99ACD2',
    fontSize: '14px',
    borderColor: '#2F3852',
    backgroundColor: '#0E101A',
  },
})

export const Input = defineMultiStyleConfig({
  variants: { input },
})
