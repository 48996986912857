import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { Details, Info, Shield } from 'assets/icons'
import React, { useState } from 'react'
import mainImg from 'assets/images/newTrendindCollection1.png'
import { ActivitesRecord } from 'utils/types/activites.type'
import { activitesRecord } from 'utils/data/activities.data'
import { createColumnHelper } from '@tanstack/react-table'
import DataTable from 'components/table'
import Header from 'components/header'
import coverBgImage from 'assets/images/myAccBgImg.png'
import { BsArrowLeft } from 'react-icons/bs'
const columnHelper = createColumnHelper<ActivitesRecord>()

const columns = [
  columnHelper.accessor('name', {
    cell: info => (
      <Flex alignItems="center" gap="15px" className="fs--14">
        <Avatar
          name="Christian Nwamba"
          src={info?.row?.original?.image}
          className="gradient-border-mask"
        />
        <Text color="text">{info?.row?.original?.name}</Text>
      </Flex>
    ),
    header: 'NAME',
  }),
  columnHelper.accessor('transactionID', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info.getValue()}
      </Text>
    ),
    header: 'TRANSACTION ID',
  }),
  columnHelper.accessor('time', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()}
      </Text>
    ),
    header: 'TIME',
  }),
  columnHelper.accessor('tokenID', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()}
      </Text>
    ),

    header: 'TOKEN ID',
  }),

  columnHelper.accessor('buyer', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()}
      </Text>
    ),
    header: 'BUYER',
  }),
  columnHelper.accessor('seller', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()}
      </Text>
    ),
    header: 'SELLER',
  }),
  columnHelper.accessor('amount', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info?.getValue()} SAR
      </Text>
    ),
    header: 'TOTAL AMOUNT',
  }),
]
const mobileViewCols = [
  columnHelper.accessor('name', {
    cell: info => (
      <Flex alignItems="center" gap="15px" className="fs--14">
        <Avatar
          name="Christian Nwamba"
          src={info?.row?.original?.image}
          className="gradient-border-mask"
        />
        <Text color="text">{info?.row?.original?.name}</Text>
      </Flex>
    ),
    header: 'NAME',
  }),
  columnHelper.accessor('transactionID', {
    cell: info => (
      <Text color="text" className="fs--14">
        {info.getValue()}
      </Text>
    ),
    header: 'TRANSACTION ID',
  }),
]
export default function ViewNFT() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLargerThan768] = useMediaQuery('(min-width: 769px)')

  return (
    <Box
      bgImage={coverBgImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <Container maxW="1280px" px="1rem">
        <Header />
        <Box gap="30px" display={{ sm: 'block', md: 'flex' }} mt="30px">
          <Box flex={0.5}>
            <Image
              src={mainImg}
              h="611px"
              objectFit="cover"
              w="100%"
              rounded="14px"
            />
          </Box>
          <Box flex={0.5}>
            <Heading className="fs--22" color="white" mb="12px">
              NFT FAN TOKENS
            </Heading>
            <Box
              border="1px"
              borderColor="border"
              borderRadius="7px"
              p="22px"
              bg="#181E30"
            >
              <HStack justify="space-between">
                <Text>List Price</Text>
                <Text>12,543 SAR</Text>
              </HStack>
              <HStack justify="space-between" mt="1rem" mb="24px">
                <Text>Royalty</Text>
                <Text>3.4 SAR</Text>
              </HStack>{' '}
              <HStack className="fw--600" justify="space-between">
                <Text color="white">Total</Text>
                <Text color="white">12d54.3 SAR</Text>
              </HStack>
              <Button
                mt="30px"
                w="100%"
                onClick={onOpen}
                variant="primary"
                _hover={{ variant: 'outline' }}
              >
                Buy NFT
              </Button>
              <BuyNFTModal isOpen={isOpen} onClose={onClose} />
            </Box>
            <Accordion allowToggle defaultIndex={[0]}>
              <AccordionItem border="0">
                <AccordionButton
                  _hover={{}}
                  bg="#232C47"
                  rounded="7px"
                  mt="18px"
                  mb="12px"
                >
                  <Flex flex="1" align="center" gap="5px" textAlign="left">
                    <Info /> <Text ml="5px">About NFT FAN TOKENS</Text>
                  </Flex>
                  <AccordionIcon fontSize="28px" color="#99ACD2" />
                </AccordionButton>

                <AccordionPanel
                  p="18px"
                  border="1px"
                  borderColor="border"
                  rounded="7px"
                  bg="#181E30"
                >
                  <Text>
                    Discover the magic of web3. NFT FAN TOKENS wants to help the
                    world embrace web3 by providing everyone with simple and
                    intuitive ways to experience crypto-native tools.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Accordion allowToggle defaultIndex={[0]}>
              <AccordionItem border="0">
                <AccordionButton
                  _hover={{}}
                  bg="#232C47"
                  rounded="7px"
                  mt="18px"
                  mb="12px"
                >
                  <Flex flex="1" align="center" gap="5px" textAlign="left">
                    <Shield /> <Text ml="5px">Arrributes</Text>
                  </Flex>
                  <AccordionIcon fontSize="28px" color="#99ACD2" />
                </AccordionButton>

                <AccordionPanel
                  p="14px"
                  border="1px"
                  borderColor="border"
                  rounded="7px"
                >
                  <Stack direction={{ base: 'column', md: 'row' }}>
                    {[1, 2, 3].map(item => (
                      <Box
                        key={item}
                        flex={0.33333}
                        bg="#181E30"
                        border="1px"
                        borderColor="border"
                        p="18px"
                        rounded="5px"
                      >
                        <Text className="fs--12">Background</Text>
                        <Text color="white" my="10px">
                          Saltwater
                        </Text>
                        <HStack justify="space-between">
                          <Tag color="text" bg="#4f81fd3d" className="fs--10">
                            30%
                          </Tag>
                          <Text className="fs--12">12 SAR</Text>
                        </HStack>
                      </Box>
                    ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Box>
        <Box gap="30px" display={{ sm: 'block', md: 'flex' }}>
          <Accordion allowToggle flex={0.5} defaultIndex={[0]}>
            <AccordionItem border="0">
              <AccordionButton
                _hover={{}}
                bg="#232C47"
                rounded="7px"
                mt="18px"
                mb="12px"
              >
                <Flex flex="1" align="center" gap="5px" textAlign="left">
                  <Info /> <Text ml="5px">Price History</Text>
                </Flex>
                <AccordionIcon fontSize="28px" color="#99ACD2" />
              </AccordionButton>

              <AccordionPanel
                p="18px"
                border="1px"
                borderColor="border"
                rounded="7px"
                bg="#181E30"
              >
                <Text>
                  Discover the magic of web3. NFT FAN TOKENS wants to help the
                  world embrace web3 by providing everyone with simple and
                  intuitive ways to experience crypto-native tools.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion allowToggle flex={0.5} defaultIndex={[0]}>
            <AccordionItem border="0">
              <AccordionButton
                _hover={{}}
                bg="#232C47"
                rounded="7px"
                mt="18px"
                mb="12px"
              >
                <Flex flex="1" align="center" gap="5px" textAlign="left">
                  <Details /> <Text ml="5px">Details</Text>
                </Flex>
                <AccordionIcon fontSize="28px" color="#99ACD2" />
              </AccordionButton>

              <AccordionPanel
                p="31px"
                border="1px"
                borderColor="border"
                rounded="7px"
                bg="#181E30"
              >
                <HStack justify="space-between">
                  <Text>Contract Address</Text>
                  <Text color="white">0X200...039</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Token ID</Text>
                  <Text color="white">21</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Token Standard</Text>
                  <Text color="white">ERC721</Text>
                </HStack>{' '}
                <HStack justify="space-between">
                  <Text>Owner</Text>
                  <Text color="white">0X200...039</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Royalties</Text>
                  <Text color="white">7.5%</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Platform fee</Text>
                  <Text color="white">2%</Text>
                </HStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        <Accordion allowToggle flex={0.5} defaultIndex={[0]}>
          <AccordionItem border="0">
            <AccordionButton
              _hover={{}}
              bg="#232C47"
              rounded="7px"
              mt="18px"
              mb="12px"
            >
              <Flex flex="1" align="center" gap="5px" textAlign="left">
                <Details /> <Text ml="5px">Activities</Text>
              </Flex>
              <AccordionIcon fontSize="28px" color="#99ACD2" />
            </AccordionButton>

            <AccordionPanel
              border="1px"
              borderColor="border"
              rounded="7px"
              bg="#181E30"
            >
              <DataTable
                columns={isLargerThan768 ? columns : mobileViewCols}
                data={activitesRecord}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Box>
  )
}
interface IModal {
  isOpen: boolean
  onClose: () => void
}
function BuyNFTModal({ isOpen, onClose }: IModal) {
  const [show, setShow] = useState('default')
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        className="glass-effect"
        bg="transparent"
        pt="30px"
        w="412px"
        m="0.6rem"
      >
        <ModalCloseButton color="white" bg="#13141A" borderRadius="full" />
        <ModalBody p="0">
          {show === 'default' && (
            <Box px="62px" pb="62px">
              {' '}
              <Heading
                textAlign="center"
                fontWeight={600}
                className="fs--16"
                color="white"
                mb="40px"
              >
                Select option
              </Heading>
              <Button
                variant="outlineltr"
                h="70px"
                w="100%"
                _hover={{ bg: 'primary.btnBg' }}
              >
                Create Wallet
              </Button>
              <Button
                variant="outlineltr"
                my="16px"
                h="70px"
                w="100%"
                _hover={{ bg: 'primary.btnBg' }}
              >
                Connect Wallet
              </Button>
              <Button
                variant="outlineltr"
                h="70px"
                w="100%"
                onClick={() => setShow('card')}
                _hover={{ bg: 'primary.btnBg' }}
              >
                Pay with Credit/Debit Card
              </Button>
            </Box>
          )}
          {show === 'card' && (
            <Box px=" 42px" pb="20px">
              <HStack>
                <BsArrowLeft color="white" />{' '}
                <Heading
                  flex={1}
                  color="white"
                  textAlign="center"
                  fontWeight={600}
                  className="fs--16"
                >
                  Pay with card
                </Heading>
              </HStack>
              <HStack mt="15px">
                <Box flex={0.6}>
                  <Text>Card Number</Text>
                  <Input placeholder="423423434" variant="input" />
                </Box>
                <Box flex={0.4}>
                  <Text>Cvv</Text>
                  <Input placeholder="322" variant="input" />
                </Box>
              </HStack>{' '}
              <Text mt="18px">Card holder name</Text>
              <Input placeholder="322" variant="input" mb="18px" />
              <Text>Expiration Date</Text>
              <HStack my="18px">
                <Box flex={0.6}>
                  <Select
                    className="fs--14"
                    color="text"
                    placeholder="Feburuy"
                    border="1px"
                    bg="#0E101A"
                    borderColor="border"
                  >
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </Box>
                <Box flex={0.4}>
                  <Select
                    className="fs--14"
                    color="text"
                    placeholder="2026"
                    border="1px"
                    bg="#0E101A"
                    borderColor="border"
                  >
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </Box>
              </HStack>
              <Divider borderColor="border" mb="18px " />
              <HStack justify="space-between" mb="5px">
                <Text>Amount</Text>
                <Text>324 SAR</Text>
              </HStack>
              <HStack justify="space-between" mb="5px">
                <Text>Royalty fee</Text>
                <Text>3.24 SAR</Text>
              </HStack>
              <HStack justify="space-between" mb="5px">
                <Text>Platform fee</Text>
                <Text>3.24 SAR</Text>
              </HStack>
              <HStack justify="space-between" mb="5px">
                <Text color="white">Total</Text>
                <Text color="white">3.24 SAR</Text>
              </HStack>
              <HStack justify="space-between" mb="5px">
                <Text color="white">Total in crypto</Text>
                <Text color="white">3.24 MATIC</Text>
              </HStack>
              <Button w="100%">Pay now</Button>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
