import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ChakraProvider, withDefaultColorScheme } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
// import { myTheme } from 'utils/customTheme'
import customTheme from 'utils/theme/customTheme'
import './assets/styles/global.scss'
import { createIDBPersister } from 'utils/types/presister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: false,
    },
  },
})

// `extendTheme` pass your custom values
const theme = extendTheme(
  customTheme,

  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister: createIDBPersister() }}
      >
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
)
