import {
  Box,
  Divider,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { GrSend } from 'react-icons/gr'
import profileAvatar from 'assets/images/cardImg.png'
import { Clock, Comment, Heart, Share } from 'assets/icons'
interface Props {
  image?: string
  comment?: string
  plainText?: string
}
export default function PostLayout({ image, comment, plainText }: Props) {
  return (
    <Box
      p="35px"
      border="1px"
      borderColor="border"
      rounded="7px"
      bg="#181E30"
      maxW="725px"
      w="100%"
    >
      <HStack>
        <Text className="fs--12">Posted by</Text>
        <Heading className="fs--16" color="white">
          Mathilda Gavarlinai
        </Heading>
        <Clock />
        <Text className="fs--12">12h ago</Text>
      </HStack>
      <HStack mt="24px" align="start">
        <VStack flex={0.1} align="start" gap="25px">
          <Image boxSize="36px" src={profileAvatar} />
          <Box textAlign="center" w="36px">
            <Heart />
            <Text color="white" className="fs--12">
              2543
            </Text>
          </Box>
          <Box textAlign="center" w="36px">
            <Comment />
            <Text color="white" className="fs--12">
              25+
            </Text>
          </Box>
          <Box textAlign="center" w="36px">
            <Share />
            <Text color="white" className="fs--12">
              Share
            </Text>
          </Box>
        </VStack>
        <Box flex={0.9}>
          {plainText ? (
            <Text>{plainText}</Text>
          ) : (
            <Image src={image} w="100%" />
          )}
          <Text my="15px">{comment}</Text>
          <Divider borderColor="text" />
          <InputGroup rounded="10px" bg="#0E101A" mt="12px">
            <Input variant="input" placeholder="write your comment here" />
            <InputRightElement>
              <IconButton
                size="sm"
                bg="#20273C"
                aria-label="send"
                icon={<GrSend fontSize="14px" />}
              />
            </InputRightElement>
          </InputGroup>
        </Box>
      </HStack>
    </Box>
  )
}
