import { ActivitesRecord } from 'utils/types/activites.type'
import img from 'assets/images/avatar.png'
export const activitesRecord: ActivitesRecord[] = [
  {
    image: img,
    name: 'Vilgo Shot',
    transactionID: '9w3...6osc',
    time: '22:05 08.06.23',
    tokenID: '9w3...6osc',
    buyer: '9w3...6osc',
    seller: '9w3...6osc',
    amount: 1245,
  },
  {
    image: img,
    name: 'Vilgo Shot',
    transactionID: '9w3...6osc',
    time: '22:05 08.06.23',
    tokenID: '9w3...6osc',
    buyer: '9w3...6osc',
    seller: '9w3...6osc',
    amount: 1245,
  },
  {
    image: img,
    name: 'Vilgo Shot',
    transactionID: '9w3...6osc',
    time: '22:05 08.06.23',
    tokenID: '9w3...6osc',
    buyer: '9w3...6osc',
    seller: '9w3...6osc',
    amount: 1245,
  },
  {
    image: img,
    name: 'Vilgo Shot',
    transactionID: '9w3...6osc',
    time: '22:05 08.06.23',
    tokenID: '9w3...6osc',
    buyer: '9w3...6osc',
    seller: '9w3...6osc',
    amount: 1245,
  },
]
