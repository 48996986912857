import React from 'react'
import {
  Box,
  Flex,
  Image,
  Text,
  HStack,
  useMediaQuery,
  Container,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { ClubCollection, collectionData } from 'utils/types/clubCollection.type'
import DataTable from 'components/table'
import GradientDividers from 'components/GradientsDevider'
import SliderCollectionCard from 'components/sliderCard'
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom'
import SliderNextArrow from 'components/sliderNextArrow'
import SliderPrevArrow from 'components/sliderPrevArrow'
const columnHelper = createColumnHelper<ClubCollection>()
const columnsData = [
  columnHelper.accessor('collection', {
    cell: info => (
      <Flex align="center">
        <Image
          rounded="full"
          boxSize="42px"
          mr="10px"
          src={info.getValue().imgUrl}
          alt="Dan Abramov"
        />
        <Text className="fs--16 fw--600" color="white">
          {info.getValue().collectionName}
        </Text>
      </Flex>
    ),
    header: 'Collection',
  }),
  columnHelper.accessor('dayVolume', {
    cell: info => (
      <Text as="p" className="fs--20" color="white">
        {info.getValue() / 10}k{' '}
        <Text className="fs--14" as="span">
          SAR
        </Text>
      </Text>
    ),
    header: 'Day Volume',
  }),
  columnHelper.accessor('floorPrice', {
    cell: info => (
      <Text as="p" className="fs--20" color="white">
        {info.getValue() / 10}k{' '}
        <Text className="fs--14" as="span">
          SAR
        </Text>
      </Text>
    ),
    header: 'Floor Price',
  }),
  columnHelper.accessor('collectionImages', {
    cell: info => (
      <HStack justify="end">
        {info.getValue().map((item, index) => {
          return (
            <Image
              boxSize="74px"
              key={index}
              rounded="10px"
              src={item}
              alt="Dan Abramov"
            />
          )
        })}
      </HStack>
    ),
    header: '',
  }),
]
const mobileViewCols = [
  columnHelper.accessor('collection', {
    cell: info => (
      <Flex align="center">
        <Image
          rounded="full"
          boxSize="42px"
          mr="10px"
          src={info.getValue().imgUrl}
          alt="Dan Abramov"
        />
        <Text className="fs--14 fw--600" color="white">
          {info.getValue().collectionName}
        </Text>
      </Flex>
    ),
    header: 'Collection',
  }),
  columnHelper.accessor('dayVolume', {
    cell: info => (
      <Text as="p" className="fs--14" color="white">
        {info.getValue() / 10}k{' '}
        <Text className="fs--12" as="span">
          SAR
        </Text>
      </Text>
    ),
    header: 'Day Volume',
  }),
  columnHelper.accessor('floorPrice', {
    cell: info => (
      <Text as="p" className="fs--14" color="white">
        {info.getValue() / 10}k{' '}
        <Text className="fs--12" as="span">
          SAR
        </Text>
      </Text>
    ),
    header: 'Floor Price',
  }),
]
export default function ClubCollectionTab() {
  const [isLargerThan768] = useMediaQuery('(min-width: 769px)')

  return (
    <>
      <Container maxW="1280px">
        <Box
          // display="none"
          w="100%"
          my="41px"
          pb="20px"
          bg="#181E30"
          rounded="6px"
          className="gradient-mask"
        >
          <DataTable
            data={collectionData}
            columns={isLargerThan768 ? columnsData : mobileViewCols}
          />
        </Box>
      </Container>
      <Box px={{ base: '0px', md: '100px' }}>
        <DisplayCollection title="Over Top Listed NFT's" />
      </Box>
      <Box px={{ base: '0px', md: '100px' }} mb="1rem">
        <DisplayCollection title="Over Top Listed NFT's" />
      </Box>
    </>
  )
}

function DisplayCollection({ title }: any) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }
  const navigate = useNavigate()

  return (
    <Container maxW="1280px" px="1rem">
      <Text className="fs--24" color="white">
        {title}
      </Text>
      <GradientDividers />
      <Box mt="20px" position="relative" mx="auto">
        <Slider {...settings}>
          {[0, 1, 2, 3, 6, 6, 6, 6, 6, 6, 4, 5].map(item => {
            return (
              <SliderCollectionCard
                key={item}
                onclick={() => navigate('/single-collection')}
              />
            )
          })}
        </Slider>
      </Box>
    </Container>
  )
}
