import { useQuery } from '@tanstack/react-query'
import { ICollections } from 'utils/types/collections.type'
import { client } from './api-client'

export const useGetAllCollection = (offset: number, limit: number) =>
  useQuery<ICollections[], Error>(['allCollections'], () =>
    client(`collection/getAllCollections?limit=${limit}&offset=${offset}`),
  )
export const useGetCollectionWithID = (id: string | undefined) =>
  useQuery<ICollections, Error>(['collectionWithID'], () =>
    client(`collection/getCollection/${id}`),
  )
